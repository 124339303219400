import apiService from "../common/api.service";

export const LOAD_MENU = 'LOAD_MENU';

export const loadMenu = () => {
	return dispatch => {
		dispatch({ type: LOAD_MENU });
	};
};

export const FETCH_MENU = 'FETCH_MENU';
export const fetchMenu = () => {
	return dispatch => {
		apiService.getMainMenu().then(menu => {
			dispatch({ type: LOAD_MENU, menu });
		});
	};
};
