import { CartItem, DeliveryInfo } from "./cart.state";

export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const removeCartItem = (index: number) => ({
    type: REMOVE_CART_ITEM,
    index
});

export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const completeOrder = () => ({
    type: COMPLETE_ORDER
});

export const CHANGE_CART_ITEM_QUANTITY = 'CHANGE_CART_ITEM_QUANTITY';
export const changeCartItemQuantity = (index: number, quantity: number) => ({
    type: CHANGE_CART_ITEM_QUANTITY,
    index,
    quantity
});

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const addProductToCart = (item: CartItem) => ({
    type: ADD_PRODUCT_TO_CART,
    item
});

export const UPDATE_DELIVERY_INFO = 'UPDATE_DELIVERY_INFO';
export const updateDeliveryInfo = (info: Partial<DeliveryInfo>) => ({
    type: UPDATE_DELIVERY_INFO,
    info
});
