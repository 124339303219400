import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { goToTop } from '../common/helpers';
import { Link } from 'react-router-dom';

class Menu extends React.Component<any, any> {

	public state = { active: null };

	constructor(props) {
		super(props);

		this.hideMenu = this.hideMenu.bind(this);
	}

	public componentDidMount() {
		document.addEventListener('click', this.hideMenu, true);
	}

	public componentWillUnmount() {
		document.removeEventListener('click', this.hideMenu, true);
	}

	public onMenuClick(menuId: string, event) {
		if (event.target.className === 'dropdown__trigger') {
      this.setState({ active: this.state.active === menuId ? null : menuId });
      goToTop();
		}
	}

	public getMenuStyles(menuId: string) {
		return  {
			opacity: this.state.active === menuId ? 1 : 0,
			display: this.state.active === menuId ? 'block' : 'none'
		};
	}

	public hideMenu(event) {
		const domNode = ReactDOM.findDOMNode(this);

		if (!domNode || !domNode.contains(event.target)) {
      this.setState({ active: null });
		}
  }

  public goToMenu(menu: string, category: string, subcategory: string) {
		this.props.unmountProducts();
    // this.props.history.push(`/products/${menu}/${category}/${subcategory}`);
    this.props.hideMenu();
		goToTop();
  }

	public render() {
    const { menu, loaded } = this.props;

    if (!loaded) {
			return (
        <ul className="menu-horizontal text-left loading-mode">
          <li className="loading-element" />
          <li className="loading-element" />
          <li className="loading-element" />
          <li className="loading-element" />
          <li className="loading-element" />
        </ul>
      );
		}

		return (
			<ul className="menu-horizontal text-left">
				{ menu.map(menu => (
					<li key={menu.id} className="dropdown" onClick={event => this.onMenuClick(menu.id, event)}>
						<a className="dropdown__trigger">{menu.name}</a>
						<div className="dropdown__container" style={{...this.getMenuStyles(menu.id)}}>
							<div className="container">
								<div className="row">
									<div className="col-md-12 dropdown__content dropdown__content--lg">
										<div className="row justify-content-start">
											{ menu.children.map(category => (
												<div key={category.id} className="col-lg-3 col-md-4">
													<h5>{category.name}</h5>
													<ul className="menu-vertical">
														{ category.children.map(subcategory => (
															<li key={subcategory.id} onClick={() => this.setState({ active: null })}>
																<Link to={`/products/${menu.url}/${category.url}/${subcategory.url}`} onClick={() => this.goToMenu(menu.url, category.url, subcategory.url)}>
																	{subcategory.name}
																</Link>
															</li>
														)) }
													</ul>
												</div>
											)) }
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				))}
			</ul>
		);
	}
}

export default withRouter(Menu);
