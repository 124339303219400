import apiService from '../common/api.service';

export const LOAD_HOME_CONTENT = 'LOAD_HOME_CONTENT';
export const FETCH_HOME_CONTENT = 'FETCH_HOME_CONTENT';
export const fetchHomeContent = () => {
	return dispatch => {
		apiService.getHomeContent().then(data => {
			dispatch({ type: LOAD_HOME_CONTENT, data });
		});
	};
};
