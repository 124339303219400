import { cartState, CartState } from './cart.state';
import { REMOVE_CART_ITEM, CHANGE_CART_ITEM_QUANTITY, ADD_PRODUCT_TO_CART, UPDATE_DELIVERY_INFO, COMPLETE_ORDER } from './cart.actions';

export default (state = cartState, action) => {
	switch (action.type) {

		case REMOVE_CART_ITEM:
			return removeCartItem(state, action);

		case CHANGE_CART_ITEM_QUANTITY:
			return changeQuantity(state, action);

		case ADD_PRODUCT_TO_CART:
			return addToCart(state, action);

		case UPDATE_DELIVERY_INFO:
      return updateDelivery(state, action);
      
    case COMPLETE_ORDER:
      return completeOrder(state, action);

		default:
			return state;
	}
};

const completeOrder = (state: CartState, action: any) => {
	const newState = { ...state };

	newState.items = [];
	newState.delivery = {};

	sessionStorage.setItem('craftio-cart', '');

	return newState;
};

const addToCart = (state: CartState, action: any) => {
	const newState = { ...state };

	const index = newState.items.findIndex(item => {
		return item.id === action.item.id && JSON.stringify(item.optionalFields) === JSON.stringify(action.item.optionalFields);
	});

	if (index >= 0) {
		newState.items[index].quantity += 1;
	} else {
		newState.items.push(action.item);
	}

	sessionStorage.setItem('craftio-cart', JSON.stringify(newState));

	return newState;
};

const removeCartItem = (state: CartState, action: any) => {
	const newState = { ...state };

	newState.items.splice(action.index, 1);

	sessionStorage.setItem('craftio-cart', JSON.stringify(newState));

	return newState;
};

const changeQuantity = (state: CartState, action: any) => {
	const newState = { ...state };

  newState.items[action.index].quantity = action.quantity;
  
	sessionStorage.setItem('craftio-cart', JSON.stringify(newState));

	return newState;
};

const updateDelivery = (state: CartState, action: any) => {
	const newState = { ...state };

	newState.delivery = { ...newState.delivery, ...action.info };

	sessionStorage.setItem('craftio-cart', JSON.stringify(newState));

	return newState;
};
