import React from 'react';
import Flickity from 'react-flickity-component';
import { Helmet } from 'react-helmet';
import { Product } from './product.state';
import OptionalField from './optional-field.component';
import { withRouter } from 'react-router-dom';
import { ProductLoading } from './product-loading.component';
import apiService from '../common/api.service';
import { goToTop } from '../common/helpers';

type MyProps = any;
type MyState = {
	submitted: boolean;
	form: any;
	price: any;
	stock: boolean;
	loading: boolean;
	deliveryPrice: any;
};
class ProductComponent extends React.Component<MyProps, MyState> {

	public props: any;
	public state = { submitted: false, form: {}, price: null, deliveryPrice: null, stock: true, loading: false };
	public flickityOptions: any = {
		cellAlign: 'left',
		wrapAround: true,
		pageDots: true,
		prevNextButtons: true,
		autoPlay: false,
		draggable: true,
		imagesLoaded: true,
		accessibility: true,
		rightToLeft: false,
		initialIndex: 0,
    freeScroll: false,
    lazyLoad: true
	};

	constructor(props) {
		super(props);

		this.onChangeOptionalField = this.onChangeOptionalField.bind(this);
		this.onAddToCart = this.onAddToCart.bind(this);
		this.addToCard = this.addToCard.bind(this);
	}

    public componentWillMount() {
		this.fetchProduct();
	}

	public componentWillUnmount() {
		this.props.unmount();
  }

  componentDidMount() {
    goToTop();
  }

	public componentWillReceiveProps() {
		if (window.location.pathname !== this.props.match.url) {
			this.fetchProduct();
		}
	}

	public changeProduct(product: any) {
		this.props.unmount();
		this.props.history.push(`/product/${product.url}`);
		goToTop();
  }

	public fetchProduct() {
    this.props.fetchProduct(window.location.pathname.replace('/product/', '').split('/')[0]);
	}

	public onChangeOptionalField(field) {
		const product: Product = this.props.product;
		const fields = product.optional_fields.fields;
		const prices = product.optional_fields.prices;
		const priceChangers = fields.filter(f => f.price_changer).map(f => f.name);

		this.setState({ ...this.state, form: { ...this.state.form, [field.name]: field.value } }, () => {
			if (priceChangers.length && priceChangers.reduce((r, i) => !!this.state.form[i], true)) {
				const priceItem = prices.find(p => priceChangers.reduce((r, i) => r && this.state.form[i] === p[i], true));
				if (priceItem) {
					this.setState({
            price: priceItem.price,
            deliveryPrice: priceItem.delivery_price,
            stock: priceItem.stock > 0
          });
				}
			}
		});
	}

	public onAddToCart() {
		this.setState({ ...this.state, submitted: true }, () => {

			if (this.isFormValid) {
        const optionalFields = this.props.product.optional_fields;
        if (optionalFields && optionalFields.fields) {
          const imageFields = optionalFields.fields.filter(field => field.type === 'Image');

          if (imageFields.length > 0) {
            this.setState({ ...this.state, loading: true });
          }

          this.uploadImageFields(imageFields.map(field => field.name)).then(this.addToCard);
        } else {
          this.addToCard();
        }
			}
		});
  }

  public addToCard() {
    window['craftioImages'] = {};

    this.props.addProductToCart({
      id: this.props.product.id,
      name: this.props.product.name,
      price: this.productPrice,
      deliveryPrice: this.state.deliveryPrice || this.props.product.delivery_price,
      quantity: 1,
      optionalFields: this.state.form,
      photo: this.props.product.photos[0].url
    });

    this.props.history.push(`/cart`);
  }

  public uploadImageFields(fields: string[]) {
    return Promise.all(fields.map(field => this.uploadImage(field, window['craftioImages'][field])));
  }

  public uploadImage(fieldName: string, file: File) {
    if (!file) {
      return Promise.resolve('');
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);

      apiService.uploadImage(formData)
        .then(data => this.setState({ ...this.state, form: { ...this.state.form, [fieldName]: data.url } }, resolve))
        .catch(reject);
    });
  }

	public isFieldValid(field) {
		if (!field.required || !this.state.submitted) {
			return true;
		}

		if (!this.state.form[field.name] || !this.state.form[field.name].length) {
			return false;
		}

		return true;
	}

	public get isFormValid() {
		const product: Product = this.props.product;
		if (!product.optional_fields || !this.state.submitted) {
			return true;
		}

		return product.optional_fields.fields.reduce((red, field) => red && this.isFieldValid(field), true);
	}

	public get productPrice() {
		const product: Product = this.props.product;

		if (this.state.price) {
			return this.state.price;
		}

		if (product.optional_fields && product.optional_fields.prices.length) {
			const productPrices = product.optional_fields.prices.map(p => p.price);
			const minPrice = Math.min(...productPrices);
			const maxPrice = Math.max(...productPrices);

			return `${minPrice} - ${maxPrice}`;
		}

		return product.price;
  }

  public render() {
		const { loaded, similar } = this.props;
		const product: Product = this.props.product;

		if (!loaded || !product) {
			return <ProductLoading />;
		}

		this.flickityOptions = {
			...this.flickityOptions,
			pageDots: product.photos.length > 1,
			prevNextButtons: product.photos.length > 1,
			draggable: product.photos.length > 1
		};

    return (
			<section className="product-page container">
				<Helmet>
					<title>{product.name} - Craftio</title>
				</Helmet>
				<div className="row justify-content-around product-main">
					<div className="col-md-7 col-lg-6 product-image-slider">
						<Flickity
							className={'carousel'}
							elementType={'div'}
							options={this.flickityOptions}
							disableImagesLoaded={false}
							reloadOnUpdate
							static
						>
							{product.photos.map((photo, i) => (
								<img key={i} data-flickity-lazyload={photo.url}/>
							))}
						</Flickity>
					</div>
					<div className="col-md-5 col-lg-5">
						<h2 className="product-name">{product.name}</h2>
						<div className="text-block">
							<span className="h4 inline-block color--primary-1">
								<i className="icon icon-Tag-4 price-icon" /> {this.productPrice} Lei
							</span>
							{this.state.stock && <span className="label stock-label bg--success">În stoc</span>}
							{!this.state.stock && <span className="label stock-label bg--empty">Stoc gol</span>}
						</div>
						<div className="row">
							<div className="col-md-12">
								{product.optional_fields && product.optional_fields.fields.map((field, i) => (
									<OptionalField key={field.name + i} index={i} field={field} onChange={this.onChangeOptionalField} valid={this.isFieldValid(field)} />
								))}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 col-lg-6">
								<button className="btn btn--primary btn--lg add-to-cart-button" onClick={this.onAddToCart} disabled={!this.state.stock || this.state.loading}>
									Adaugă în coș
								</button>
							</div>
							{!this.isFormValid && <p className="col-12 color--error">Campurile marcate cu rosu sunt obligatorii!</p>}
						</div>
						<div className="row delivery-info-wrapper">
							{product.delivery_info && (<div className="delivery-info-block col-12">
								<div className="delivery-info-block-header">Opțiuni livrare</div>
								<p dangerouslySetInnerHTML={{__html: product.delivery_info}} />
							</div>)}
						</div>
					</div>
				</div>
				<hr/>
				<div className="row product-description">
					<div className="col-12">
						{product.description && (<p dangerouslySetInnerHTML={{__html: product.description}} />)}
					</div>
				</div>

				<h3 className="similar-title">Nu e ceea ce cauți? Încearcă asta…</h3>

				<div className="row similar-products">
					{similar.map((prod: any, i) => (
						<div className="col-md-3 product-page-item" key={prod.id}>
							<div className="project-thumb hover-element border--round feature">
								<a onClick={() => this.changeProduct(prod)}>
									<div className="hover-element__initial">
										<div
											className="background-image-holder rect-comp"
											style={{
												background: `url("${prod.photo}")`,
												opacity: 1
											}}
										/>
									</div>
									<div className="hover-element__reveal" data-scrim-top="5">
										<div className="project-thumb__title">
											<h4>{prod.name}</h4>
											<span>{prod.price} Lei</span>
										</div>
									</div>
								</a>
							</div>
						</div>
					))}
				</div>
			</section>
		);
  }
}

export default withRouter(ProductComponent);
