import React from 'react';
import { Route } from 'react-router-dom';
import Home from './home/home.container';
import Header from './header/header.container';
import Footer from './footer/footer.container';
import ProductList from './product-list/product-list.container';
import Product from './product/product.container';
import Pages from './pages/pages.container';
import Cart from './cart/shopping-cart/shopping-cart.container';
import Delivery from './cart/delivery/delivery.container';
import Layout from './layout';
import Confirmation from './cart/confirmation/confirmation.component';
import Error from './cart/error/error.component';

const App = () => (
	<Layout>
		<Header />
		<div className="main-container">
			<Route exact path="/" component={Home} />
			<Route exact path="/products/:menu/:category/:subcategory" component={ProductList} />
			<Route exact path="/product/:name" component={Product} />
			<Route exact path="/pages/:name" component={Pages} />
			<Route exact path="/cart" component={Cart} />
			<Route exact path="/cart/delivery" component={Delivery} />
			<Route exact path="/cart/confirmation" component={Confirmation} />
			<Route exact path="/cart/error" component={Error} />
		</div>
		<Footer />
	</Layout>
);

export default App;
