import { productInitialState, ProductState } from './product.state';
import { LOAD_PRODUCT, UNLOAD_PRODUCT } from './product.actions';

export default (state: ProductState = productInitialState, action) => {
	switch (action.type) {

		case LOAD_PRODUCT:
			return loadProduct(state, action);

		case UNLOAD_PRODUCT:
			return unloadProduct(state, action);

		default:
			return state;
	}
};

const unloadProduct = (state: ProductState, action: any) => {
	const newState = { ...state };

	newState.similar = null;
	newState.product = null;
	newState.loaded = false;

	return newState;
};

const loadProduct = (state: ProductState, action: any) => {
	const newState = { ...state };

	newState.loaded = true;
	newState.product = action.data.products[0];
	newState.similar = action.data.similarProducts.map(p => ({ ...p, photo: p.photos[0].url }));

	return newState;
};
