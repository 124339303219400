import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ProductListLoading } from './product-list-loading.component';
import { goToTop } from '../common/helpers';

class ProductList extends Component {

	public props: any;

  public componentWillMount() {
    this.fetchProducts();
	}

	public componentWillUnmount() {
		this.props.unmount();
	}

	public componentWillReceiveProps(props) {
    if (window.location.pathname !== this.props.match.url && !props.productList.loaded) {
      this.fetchProducts();
    }
  }

  componentDidMount() {
    goToTop();
  }

	public fetchProducts() {
    const params = this.props.history.location.pathname.split('/').slice(2);
    this.props.fetchCategoryProducts(params[0], params[1], params[2]);
  }

  public get description(): string {
    let parser = new DOMParser().parseFromString(this.props.productList.subcategory.description, "text/xml");
    return parser && parser.firstChild ? parser.firstChild['innerHTML'] : '';
  }

  public render() {
		const { productList: { products, category, subcategory, loaded } } = this.props;

		if (!loaded) {
			return <ProductListLoading />;
    }

    return (
			<div className="product-list-page">
				<Helmet>
					<title>{subcategory.name} - Craftio</title>
          <meta name="description" content={subcategory.description ? subcategory.description.replace(/<\/?[^>]+(>|$)/g, "").replace('&nbsp;', '') : ''} />
          <meta name="keywords" content={subcategory.keywords ? subcategory.keywords : ''} />
				</Helmet>
				<section className="container">
					<div className="row">
						<div className="col-md-12 products-page-header">
							<h1>{category} - {subcategory.name}</h1>
							{subcategory.description && (<p dangerouslySetInnerHTML={{__html: subcategory.description}} />)}
							<hr/>
						</div>
					</div>
					<div className="row">
						{products.map((product, i) => (
							<div className="col-md-3 products-page-item" key={product.id}>
								<div className="project-thumb hover-element border--round feature">
									<Link to={`/product/${product.url}`}>
										<div className="hover-element__initial">
											<div
												className="background-image-holder rect-comp"
												style={{
													background: `url("${product.photo}")`,
													opacity: 1
												}}
											/>
										</div>
										<div className="hover-element__reveal" data-scrim-top="5">
											<div className="project-thumb__title">
												<h4>{product.name}</h4>
												<span>{product.price} Lei</span>
											</div>
										</div>
									</Link>
								</div>
							</div>
						))}
					</div>
				</section>
			</div>
		);
    }
}

export default withRouter(ProductList);
