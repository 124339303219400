import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ErrorIcon from './cancel.png';
import { goToTop } from '../../common/helpers';

type MyProps = any;
type MyState = any;
class Error extends React.Component<MyProps, MyState> {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    goToTop();
  }

  render() {
    return (
      <section className="container cart-component">
        <Helmet>
          <title>Plata online esuata - Craftio</title>
        </Helmet>
      
        <div className="row confirmation-page">
          <div className="col-md-12">
            <div className="boxed boxed--border">
  
              <img className="confirmation-page-icon" src={ErrorIcon} />
              <h2 className="confirmation-page-text">Eroare!</h2>
              <h4 className="confirmation-page-text">Eroare la procesarea platii online. Contactati-ne pentru a rezolva problema!</h4>
  
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Error;
