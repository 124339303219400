import React from 'react';
import { Link } from 'react-router-dom';
import { FacebookProvider, Page } from 'react-facebook';
import PaymentsImage from './payment.jpg';
import { FooterLoading } from './footer-loading.component';

export const Footer = ({ pages, constants, loaded }) => {

	if (!loaded) {
		return <FooterLoading />;
	}

	return (
		<footer className="space--sm footer-2 bg--secondary">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 col-md-6 col-lg-3">
						<h6 className="type--uppercase">Craftio</h6>
						<ul className="list--hover">
							{pages.map(page => (
								<li key={page.id}>
									<Link to={`/pages/${page.url}`}>{page.title}</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-4">
            <FacebookProvider appId="914366985627519">
              <Page href={constants.facebook.value} />
            </FacebookProvider>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-5">
						<h6 className="type--uppercase">Contactează-ne</h6>
						<ul className="list--hover footer-contacts">
							<li>
								<h3><i className="icon icon-Phone" /> {constants.phone.value}</h3>
							</li>
							<li>
								<h3><i className="icon icon-Phone" /> {constants.phone2.value}</h3>
							</li>
							<li>
								<h3><i className="icon icon-Mail" /> {constants.email.value}</h3>
							</li>
							<li className="payment-methods">
								<img src={PaymentsImage} alt="Payment methods"/>
							</li>
						</ul>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<span className="type--fine-print">©
							<span className="update-year">2019</span> Craftio by <a target="_blank" href="https://bolbosenco.com">WebSketch</a></span>
					</div>
					<div className="col-md-6 text-right text-left-xs">
						<ul className="social-list list-inline list--hover">
							<li>
								<a target="_blank" href={constants.facebook.value}>
									<i className="socicon socicon-facebook icon icon--xs" />
								</a>
							</li>
							<li>
								<a target="_blank" href={constants.instagram.value}>
									<i className="socicon socicon-instagram icon icon--xs" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
};
