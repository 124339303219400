import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchMenu } from './header/header.actions';
import { fetchFooter } from './footer/footer.actions';
import { AppState } from './root-reducer';

class Layout extends Component {

    public props: any;

    constructor(props) {
        super(props);
    }

    public componentWillMount() {
        this.props.fetchMenu();
        this.props.fetchFooter();
    }

    public render() {
        return this.props.children;
    }
}

export const mapStateToProps = () => ({});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ fetchFooter, fetchMenu },
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Layout);
