class ApiService {

    private graphqlPath = 'https://craftio-admin.herokuapp.com/graphql'; // window.location.origin === 'http://localhost:3000' ? 'http://localhost:1337/graphql' : 'https://craftio-admin.herokuapp.com/graphql';
    private apiPath = 'https://craftio-admin.herokuapp.com'; // window.location.origin === 'http://localhost:3000' ? 'http://localhost:1337' : 'https://craftio-admin.herokuapp.com';
    private mediaPath = 'https://media.craftio.md';

    public addOrder({ delivery, items }, freeDelivery) {
      const { payment, name, phone, details, ...address} = delivery;
      return fetch(`${this.apiPath}/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ freeDelivery, name, address, payment, phone, details, status: 'Nou', products: items })
      }).then(response => response.json());
    }

    public uploadImage(body) {
      return fetch(this.mediaPath, { method: 'POST', body }).then(response => response.json());
    }

    public deleteImage(body) {
      return fetch(this.mediaPath, { method: 'DELETE', body }).then(response => response.json());
    }

    public fetchFooterData() {
        return this.fetch(`{
            constants {
                key
                Label
                value
            }
            pages(sort: "order") {
                id
                title
                url
                content
            }
        }`);
    }

    public getHomeContent() {
        return this.fetch(`{
            sliders(sort: "order") {
                url
                order
                image {
                    url
                }
            }
            products(where: { featured: true }) {
                id
                name
                url
                price
                photos {
                    url
                }
            }
        }`);
    }

    public getCategoryProducts(menu: string, category: string, subcategory: string) {
        return this.fetch(`{
            categories(where: { url: "${category}" }) {
                name
            }
            subcategories(where: { url: "${subcategory}" }) {
                name
                keywords
                description
            }
            searchByCategories(menu: "${menu}", category: "${category}", subcategory: "${subcategory}") {
                id
                name
                url
                price
                photos {
                    url
                }
            }
        }`);
    }

    public headerSearch(query: string) {
      return this.fetch(`{
          headerSearch(query: "${query}", count: 10) {
              id
              url
              name
              price
              photos {
                  url
              }
          }
      }`);
  }

    public getProduct(url: string) {
        return this.fetch(`{
            products(where: {url: "${url}"}) {
                id
                url
                name
                price
                delivery_price
                description
                delivery_info
                optional_fields
                photos {
                    url
                }
            }
            similarProducts(url: "${url}"){
                id
                url
                name
                price
                photos {
                    url
                }
            }
        }`);
    }

    public getMenuPaths() {
      return fetch(`${this.apiPath}/menu/paths`).then(response => response.json());
    }

    public getProductPaths() {
      return this.fetch(`{
        products {
          name
        }
      }`);
    }

    public getMainMenu() {
      return fetch(`${this.apiPath}/menu`).then(response => response.json());
    }

    private fetch(query) {
        return fetch(`${this.graphqlPath}?query=${query}`)
            .then(response => response.json())
            .then(json => json.data);
    }
}

export default new ApiService();
