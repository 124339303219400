import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { HomeLoading } from './home-loading.component';
import { Helmet } from 'react-helmet';

export const Home = ({ loaded, sliders, featuredItems, features }) => {

	if (!loaded) {
		return <HomeLoading />;
  }



	const shuffleArray = (unsortedArray: any[]): any[] => {
		const array = [...unsortedArray];
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	};

	const featuredProducts = shuffleArray(featuredItems).slice(0, 10);
	const featuredCol1 = featuredProducts.slice(0, 5);
  const featuredCol2 = featuredProducts.slice(5, 10);
  const title = features['home-page-title'];
  const keywords = features['home-page-keywords'];

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

	return (
		<div className="home-page">
			<Helmet>
				{title ? <title>{title.value}</title> : null}
        {keywords ? <meta content={keywords.value} name="keywords" /> : null}
        <meta name="description" content="Magazin online de cadouri personalizate cu livrare rapidă în toată Moldova. Cadouri originale pentru zi de naștere, aniversare, nuntă, botez sau alte ocazii speciale." />
			</Helmet>

			<section className="switchable switchable--switch space--xs home-slider">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
            <Slider {...settings}>
              {sliders.map((slider, i) => slider.link ? (
                <img key={i} src={slider.image} onClick={() => window.location.href = slider.link} style={{ cursor: 'pointer' }} />
              ) : (
                <img key={i} src={slider.image}/>
              ))}
            </Slider>
						</div>
					</div>
				</div>
			</section>

			<section className="home-features">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="feature feature--featured feature-2 boxed boxed--border">
								<i className="icon icon-Air-Balloon color--primary" />
								<div className="feature__body">
									<h5>{features.feature_1 ? features.feature_1.Label : ''}</h5>
									<p>{features.feature_1 ? features.feature_1.value : ''}</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="feature feature--featured feature-2 boxed boxed--border">
								<i className="icon icon-Clock color--primary" />
								<div className="feature__body">
									<h5>{features.feature_2 ? features.feature_2.Label : ''}</h5>
									<p>{features.feature_2 ? features.feature_2.value : ''}</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="feature feature--featured feature-2 boxed boxed--border">
								<i className="icon icon-Shop color--primary" />
								<div className="feature__body">
									<h5>{features.feature_3 ? features.feature_3.Label : ''}</h5>
									<p>{features.feature_3 ? features.feature_3.value : ''}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="home-featured-products">
				<div className="container">
					<div className="row">
						<div className="col-md-6 home-page-product-column">
							<div className="row">
								{featuredCol1.map((product, i) => (
									<div className={`col-md-${i === 0 ? 12 : 6}`} key={product.id}>
										<div className={`project-thumb hover-element border--round feature${i === 0 ? ' feature-big' : ''}`}>
											<Link to={`/product/${product.url}`}>
												<div className="hover-element__initial">
													<div
														className="background-image-holder rect-comp"
														style={{
															background: `url("${product.image}")`,
															opacity: 1
														}}
													/>
												</div>
												<div className="hover-element__reveal" data-scrim-top="5">
													<div className="project-thumb__title">
														<h4>{product.name}</h4>
														<span>{product.price} Lei</span>
													</div>
												</div>
											</Link>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="col-md-6 home-page-product-column">
							<div className="row">
								{featuredCol2.map((product, i) => (
									<div className={`col-md-${i === 4 ? 12 : 6}`} key={product.id}>
										<div className={`project-thumb hover-element border--round feature${i === 4 ? ' feature-big' : ''}`}>
											<Link to={`/product/${product.url}`}>
												<div className="hover-element__initial">
													<div
														className="background-image-holder rect-comp"
														style={{
															background: `url("${product.image}")`,
															opacity: 1
														}}
													/>
												</div>
												<div className="hover-element__reveal" data-scrim-top="5">
													<div className="project-thumb__title">
														<h4>{product.name}</h4>
														<span>{product.price} Lei</span>
													</div>
												</div>
											</Link>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};