import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import apiService from '../../common/api.service';
import { goToTop } from '../../common/helpers';

type MyProps = any;
type MyState = any;
class Cart extends React.Component<MyProps, MyState> {

  constructor(props) {
    super(props);

    this.removeItemFromCart = this.removeItemFromCart.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
  }

  componentDidMount() {
    goToTop();
  }

  mediaPath = 'https://media.craftio.md/optional-fields';

  public removeItemFromCart(index: number) {
    const { cart, removeCartItem } = this.props;

    const optionalFields: string[] = Object.values(cart.items[index].optionalFields);
    const images = Object.values(optionalFields).filter(i => i.startsWith(this.mediaPath));

    images.forEach(image => apiService.deleteImage(image));
    removeCartItem(index);
  }

  public changeQuantity(index: number, qunatity: number) {
    if (qunatity === 0) {
      this.removeItemFromCart(index);
    } else {
      this.props.changeCartItemQuantity(index, qunatity);
    }
  }

  public get emptyCart() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center empty-cart-block">
            <span className="h5">Cosul de cuparaturi este gol.</span>
          </div>
        </div>
      </div>
    )
  };

	render() {
    const { cart } = this.props;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    return (
      <section className="container cart-component">
        <Helmet>
          <title>Coș de cumpărături - Craftio</title>
        </Helmet>
        <div className="row">
          <div className="col-md-12 page-title">
            <h1>Coș de cumpărături</h1>
          </div>
        </div>
        <div className="row">
          {cart.items.length ? (<div className="col-md-12">
            {!isMobile && <div className="boxed boxed--border desktop-version">
              <div className="row">
                <div className="col-7">
                  <span className="h5">Produs</span>
                </div>
                <div className="col-1">
                  <span className="h5">Cant.</span>
                </div>
                <div className="col-2 text-center">
                  <span className="h5">Preț/buc</span>
                </div>
                <div className="col-1 text-right">
                  <span className="h5">Total</span>
                </div>
              </div>
              <hr />
              {cart.items.map((item, i) => (
                <div className="row cart-row" key={item.name + i}>
                  <div className="col-1">
                    <img src={item.photo} />
                  </div>
                  <div className="col-6">
                    <span className="h5 product-name">{item.name}</span>
                    {Object.keys(item.optionalFields).map(key => (
                      <span key={key}>{key}: {item.optionalFields[key].startsWith(this.mediaPath) ? (<a target="_blank" href={item.optionalFields[key]}>Imagine</a>) : item.optionalFields[key]} <br/></span>
                    ))}
                  </div>
                  <div className="col-1">
                    <span><input type="number" value={item.quantity || 1} min="0" onChange={e => this.changeQuantity(i, parseInt(e.target.value))} /></span>
                  </div>
                  <div className="col-2 text-center">
                    <span className="h5">{item.price} Lei</span>
                  </div>
                  <div className="col-1 text-right">
                    <span className="h5">{item.price * item.quantity} Lei</span>
                  </div>
                  <div className="col-1 text-right">
                    <i className="material-icons remove-cart-item" onClick={() => this.removeItemFromCart(i)}>close</i>
                  </div>
                </div>
              ))}
            </div>}

            {isMobile && <div className="boxed boxed--border mobile-version">
              {cart.items.map((item, i) => (
                <div className="cart-row" key={item.name + i}>
                  <div className="row">
                    <div className="col-1">
                      <img src={item.photo} />
                    </div>
                    <div className="col-10">
                      <span className="h5 product-name" style={{paddingLeft: '11px'}}>{item.name}</span>
                    </div>
                    <div className="col-1 text-right">
                      <i className="material-icons remove-cart-item" onClick={() => this.removeItemFromCart(i)}>close</i>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '11px'}}>
                    <div className="col-6" style={{fontSize: '11px', lineHeight: '14px'}}>
                      {Object.keys(item.optionalFields).map(key => (
                        <span key={key}>{key}: {item.optionalFields[key].startsWith(this.mediaPath) ? (<a target="_blank" href={item.optionalFields[key]}>Imagine</a>) : item.optionalFields[key]} <br/></span>
                      ))}
                    </div>
                    <div className="col-2">
                      <span><input type="number" value={item.quantity || 1} min="0" style={{width: '40px'}} onChange={e => this.changeQuantity(i, parseInt(e.target.value))} /></span>
                    </div>
                    <div className="col-2 text-center">
                      <span className="h5">{item.price} Lei</span>
                    </div>
                    <div className="col-2 text-right">
                      <span className="h5">{item.price * item.quantity} Lei</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>}

            <div className="row justify-content-end">
              <div className="col-lg-2 text-right text-center-xs">
                <Link to="/cart/delivery">
                  <button type="submit" className="btn btn--primary">Continuă »</button>
                </Link>
              </div>
            </div>
          </div>) : this.emptyCart}
        </div>
      </section>
    );
  }
};

export default Cart;
