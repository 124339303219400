import React from 'react';

export const ProductListLoading = () => {
	return (
		<div className="product-list-page loading-mode">
			<section className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="loading-title" />
						<div className="loading-description" />
						<div className="loading-description" />
						<hr/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-3">
						<div className="project-thumb hover-element border--round feature" />
					</div>
				</div>
			</section>
		</div>
	);

};
