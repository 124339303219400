import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProductComponent from './product.component';
import { fetchProduct, UNLOAD_PRODUCT } from './product.actions';
import { AppState } from '../root-reducer';
import { addProductToCart } from '../cart/cart.actions';

export const mapStateToProps = ({ product }: AppState) => {
	return {
		product: product.product,
		similar: product.similar,
		loaded: product.loaded
	};
};

export const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchProduct: (id: string) => dispatch(() => fetchProduct(id)),
		unmount: () => dispatch({ type: UNLOAD_PRODUCT }),
		addProductToCart
	}, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductComponent);
