import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cart from './shopping-cart.component';
import { AppState } from '../../root-reducer';
import { removeCartItem, changeCartItemQuantity } from '../cart.actions';

export const mapStateToProps = ({ cart }: AppState) => ({
	cart
});

export const mapDispatchToProps = dispatch => bindActionCreators({
	removeCartItem,
	changeCartItemQuantity
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Cart);
