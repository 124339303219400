export interface OptionalField {
	type: string;
	name: string;
	placeholder?: string | null;
	limit?: number | null;
	price_changer?: boolean | null;
	required?: boolean;
	options: string[] | null;
}

interface OptionalFields {
	fields: OptionalField[];
	prices: any;
}

interface ProductPhoto {
	url: string;
}

export interface Product {
	id: string;
	name: string;
	description: string;
	delivery_info: string;
	price: number;
	delivery_price: number;
	photos: ProductPhoto[];
	optional_fields: OptionalFields;
}

export interface ProductState {
	loaded: boolean;
	product: null | Product;
	similar: null | any[];
}

export const productInitialState: ProductState = {
	loaded: false,
	product: null,
	similar: null
};
