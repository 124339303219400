import { HeaderState } from './header.state';
import { LOAD_MENU } from './header.actions';

export default (state = HeaderState, action) => {
	switch (action.type) {

		case LOAD_MENU:
			return composeMenu(state, action);

		default:
			return state;
	}
};

const composeMenu = (state: HeaderState, action: any) => {
	const newState = { ...state };

	newState.menu = action.menu;
	newState.loaded = true;

	return newState;
};
