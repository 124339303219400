export default {
  'Chișinău': [
    'Chișinău',
    'Băcioi',
    'Bîc',
    'Brăila',
    'Bubuieci',
    'Budești',
    'Buneți',
    'Ceroborta',
    'Cheltuitori',
    'Ciorescu',
    'Codru',
    'Colonița',
    'Condrița',
    'Cricova',
    'Cruzești',
    'Dobrogea',
    'Dumbrava',
    'Durlești',
    'Făurești',
    'Frumușica',
    'Ghidighici',
    'Goian',
    'Goianul Nou',
    'Grătiești',
    'Hulboaca',
    'Humulești',
    'Revaca',
    'Stăuceni',
    'Străisteni',
    'Sîngera',
    'Tohatin',
    'Trușeni',
    'Vadul lui Vodă',
    'Vatra',
    'Văduleni'
  ],
  'Bălți': [
    'Bălți',
    'Elizaveta',
    'Sadovoe',
  ],
  'Bender': [
    'Bender',
    'Proteagailovca'
  ],
  'Anenii Noi': [
    'Albinița',
    'Anenii Noi',
    'Balmaz',
    'Batîc',
    'Beriozchi',
    'Botnărești',
    'Botnăreștii Noi',
    'Bulboaca',
    'Calfa',
    'Calfa Nouă',
    'Chetrosu',
    'Chirca',
    'Ciobanovca',
    'Cobusca Nouă',
    'Cobusca Veche',
    'Crețoaia',
    'Delacău',
    'Floreni',
    'Florești',
    'Geamăna',
    'Gura Bîcului',
    'Hîrbovăț',
    'Hîrbovățul Nou',
    'Larga',
    'Maximovca',
    'Mereni',
    'Merenii Noi',
    'Mirnoe',
    'Nicolaevca',
    'Ochiul Roș',
    'Picus',
    'Puhăceni',
    'Roșcani',
    'Ruseni',
    'Salcia',
    'Socoleni',
    'Speia',
    'Șerpeni',
    'Telița',
    'Telița Nouă',
    'Todirești',
    'Troița Nouă',
    'Țînțăreni',
    'Varnița',
    'Zolotievca',
  ],
  'Basarabeasca': [
    'Abaclia',
    'Basarabeasca',
    'Bașcalia',
    'Bogdanovca',
    'Carabetovca',
    'Carabiber',
    'Iordanovca',
    'Iserlia',
    'Ivanovca',
    'Sadaclia',
  ],
  'Briceni': [
    'Balasinești',
    'Bălcăuți',
    'Beleavinți',
    'Berlinți',
    'Bezeda',
    'Bocicăuți',
    'Bogdănești',
    'Briceni',
    'Bulboaca',
    'Caracușenii Vechi',
    'Caracușenii Noi',
    'Chirilovca',
    'Colicăuți',
    'Corjeuți',
    'Coteala',
    'Cotiujeni',
    'Criva',
    'Drepcăuți',
    'Grimești',
    'Grimăncăuți',
    'Groznița',
    'Halahora de Jos',
    'Halahora de Sus',
    'Hlina',
    'Larga',
    'Lipcani',
    'Mărcăuți',
    'Mărcăuții Noi',
    'Medveja',
    'Mihăileni',
    'Pavlovca',
    'Pererîta',
    'Slobozia-Medveja',
    'Slobozia-Șirăuți',
    'Șirăuți',
    'Tabani',
    'Tețcani',
    'Trebisăuți',
    'Trestieni',
  ],
  'Cahul': [
    'Alexandru Ioan Cuza',
    'Alexanderfeld',
    'Andrușul de Jos',
    'Andrușul de Sus',
    'Badicul Moldovenesc',
    'Baurci-Moldoveni',
    'Borceag',
    'Bucuria',
    'Burlacu',
    'Burlăceni',
    'Brînza',
    'Cahul',
    'Chioselia Mare',
    'Chircani',
    'Cîșlița-Prut',
    'Colibași',
    'Cotihana',
    'Crihana Veche',
    'Cucoara',
    'Doina',
    'Frumușica',
    'Găvănoasa',
    'Giurgiulești',
    'Greceni',
    'Huluboaia',
    'Hutulu',
    'Iasnaia Poleana',
    'Iujnoe',
    'Larga Nouă',
    'Larga Veche',
    'Lebedenco',
    'Lopățica',
    'Lucești',
    'Manta',
    'Moscovei',
    'Nicolaevca',
    'Paicu',
    'Pașcani',
    'Pelinei',
    'Roșu',
    'Rumeanțev',
    'Sătuc',
    'Slobozia Mare',
    'Spicoasa',
    'Taraclia de Salcie',
    'Tartaul de Salcie',
    'Tătărești',
    'Tretești',
    'Trifeștii Noi',
    'Tudorești',
    'Ursoaia',
    'Vadul lui Isac',
    'Văleni',
    'Vladimirovca',
    'Zîrnești',
  ],
  'Camenca': [
    'Camenca',
    'Caterinovca',
    'Crasnîi Octeabri',
    'Cuzmin',
    'Hristovaia',
    'Hrușca',
    'Ocnița',
    'Podoima',
    'Rașcov',
    'Rotari',
    'Severinovca',
    'Slobozia-Rașcov',
    'Valea Adîncă',
  ],
  'Călărași': [
    'Bahmut',
    'Bahu',
    'Bravicea',
    'Buda',
    'Bularda',
    'Căbăiești',
    'Călărași',
    'Dereneu',
    'Duma',
    'Frumoasa',
    'Hirova',
    'Hîrbovăț',
    'Hîrjauca',
    'Hoginești',
    'Horodiște',
    'Leordoaia',
    'Meleșeni',
    'Mîndra',
    'Nișcani',
    'Novaci',
    'Onișcani',
    'Oricova',
    'Palanca',
    'Parcani',
    'Păulești',
    'Peticeni',
    'Pitușca',
    'Pîrjolteni',
    'Podul Lung',
    'Răciula',
    'Rădeni',
    'Sadova',
    'Săseni',
    'Schinoasa',
    'Seliștea Nouă',
    'Sipoteni',
    'Sverida',
    'Temeleuți',
    'Tuzara',
    'Țibirica',
    'Ursari',
    'Vălcineț',
    'Vărzăreștii Noi',
  ],
  'Cantemir': [
    'Acui',
    'Alexandrovca',
    'Antonești',
    'Baimaclia',
    'Bobocica',
    'Cania',
    'Cantemir',
    'Capaclia',
    'Chioselia',
    'Ciobalaccia',
    'Cîietu',
    'Cîrpești',
    'Cîșla',
    'Crăciun',
    'Cociulia',
    'Constantinești',
    'Coștangalia',
    'Dimitrova',
    'Enichioi',
    'Flocoasa',
    'Floricica',
    'Ghioltosu',
    'Gotești',
    'Haragîș',
    'Hănăseni',
    'Hîrtop',
    'Iepureni',
    'Lărguța',
    'Leca',
    'Lingura',
    'Pleșeni',
    'Plopi',
    'Popovca',
    'Porumbești',
    'Sadîc',
    'Stoianovca',
    'Suhat',
    'Șamalia',
    'Șofranovca',
    'Taraclia',
    'Taraclia',
    'Tartaul',
    'Tătărășeni',
    'Toceni',
    'Țărăncuța',
    'Țiganca',
    'Țiganca Nouă',
    'Țolica',
    'Victorovca',
    'Vișniovca',
    'Vîlcele',
  ],
  'Căușeni': [
    'Baccealia',
    'Baimaclia',
    'Baurci',
    'Căinari',
    'Căușeni',
    'Chircăiești',
    'Chircăieștii Noi',
    'Chițcani',
    'Ciuflești',
    'Cîrnățeni',
    'Cîrnățenii Noi',
    'Constantinovca',
    'Copanca',
    'Coșcalia',
    'Cremenciug',
    'Fîrlădeni',
    'Fîrlădenii Noi',
    'Florica',
    'Gîsca',
    'Grădinița',
    'Grigorievca',
    'Hagimus',
    'Leuntea',
    'Marianca de Sus',
    'Merenești',
    'Opaci',
    'Pervomaisc',
    'Plop',
    'Plop-Știubei',
    'Săiți',
    'Sălcuța',
    'Sălcuța Nouă',
    'Surchiceni',
    'Ștefănești',
    'Taraclia',
    'Tănătari',
    'Tănătarii Noi',
    'Tocuz',
    'Tricolici',
    'Ucrainca',
    'Ursoaia',
    'Ursoaia Nouă',
    'Valea Verde',
    'Zahorna',
    'Zaim',
    'Zviozdocica',
  ],
  'Cimișlia': [
    'Albina',
    'Artimonovca',
    'Batîr',
    'Bogdanovca Nouă',
    'Bogdanovca Veche',
    'Cenac',
    'Cimișlia',
    'Ciucur-Mingir',
    'Codreni',
    'Coștangalia',
    'Dimitrovca',
    'Ecaterinovca',
    'Fetița',
    'Gradiște',
    'Gura Galbenei',
    'Hîrtop',
    'Ialpug',
    'Ialpujeni',
    'Iurievca',
    'Ivanovca Nouă',
    'Javgur',
    'Lipoveni',
    'Marienfeld',
    'Maximeni',
    'Mereni',
    'Mihailovca',
    'Munteni',
    'Porumbrei',
    'Prisaca',
    'Sagaidac',
    'Sagaidacul Nou',
    'Satul Nou',
    'Schinoșica',
    'Selemet',
    'Suric',
    'Topala',
    'Troițcoe',
    'Valea Perjei',
    'Zloți',
  ],
  'Criuleni': [
    'Bălăbănești',
    'Bălășești',
    'Bălțata',
    'Bălțata de Sus',
    'Boșcana',
    'Chetroasa',
    'Cimișeni',
    'Ciopleni',
    'Corjova',
    'Coșernița',
    'Criuleni',
    'Cruglic',
    'Dolinnoe',
    'Drăsliceni',
    'Dubăsarii Vechi',
    'Hîrtopul Mare',
    'Hîrtopul Mic',
    'Hrușova',
    'Ișnovăț',
    'Izbiște',
    'Jevreni',
    'Logănești',
    'Mașcăuți',
    'Mărdăreuca',
    'Măgdăcești',
    'Mălăiești',
    'Mălăieștii Noi',
    'Miclești',
    'Ohrincea',
    'Onițcani',
    'Pașcani',
    'Porumbeni',
    'Ratuș',
    'Răculești',
    'Rîșcova',
    'Sagaidac',
    'Sagaidacul de Sus',
    'Slobozia-Dușca',
    'Stețcani',
    'Valea Coloniței',
    'Valea Satului',
    'Zăicana',
    'Zolonceni',
  ],
  'Dondușeni': [
    'Arionești',
    'Baraboi',
    'Boroseni',
    'Braicău',
    'Briceni',
    'Briceva',
    'Caraiman',
    'Cernoleuca',
    'Climăuți',
    'Codrenii Noi',
    'Corbu',
    'Crișcăuți',
    'Dondușeni',
    'Elenovca',
    'Elizavetovca',
    'Frasin',
    'Horodiște',
    'Moșana',
    'Octeabriscoe',
    'Pivniceni',
    'Plop',
    'Pocrovca',
    'Rediul Mare',
    'Scăieni',
    'Sudarca',
    'Teleșeuca',
    'Teleșeuca Nouă',
    'Tîrnova',
    'Țaul',
  ],
  'Drochia': [
    'Antoneuca',
    'Baroncea',
    'Baroncea Nouă',
    'Ceapaevca',
    'Chetrosu',
    'Cotova',
    'Dominteni',
    'Drochia',
    'Drochia',
    'Fîntînița',
    'Gribova',
    'Hăsnășenii Mari',
    'Hăsnășenii Noi',
    'Holoșnița Nouă',
    'Iliciovca',
    'Lazo',
    'Maramonovca',
    'Măcăreuca',
    'Miciurin',
    'Mîndîc',
    'Moara de Piatră',
    'Nicoreni',
    'Ochiul Alb',
    'Palanca',
    'Pelinia',
    'Pervomaiscoe',
    'Petreni',
    'Popeștii de Jos',
    'Popeștii de Sus',
    'Popeștii Noi',
    'Sergheuca',
    'Sofia',
    'Șalvirii Noi',
    'Șalvirii Vechi',
    'Șuri',
    'Șurii Noi',
    'Țarigrad',
    'Zgurița',
  ],
  'Dubăsari': [
    'Cocieri',
    'Corjova',
    'Coșnița',
    'Doroțcaia',
    'Holercani',
    'Mahala',
    'Mărcăuți',
    'Molovata',
    'Molovata Nouă',
    'Oxentea',
    'Pîrîta',
    'Pohrebea',
    'Roghi',
    'Ustia',
    'Vasilievca',
  ],
  'Edineț': [
    'Alexăndreni',
    'Alexeevca',
    'Bădragii Noi',
    'Bădragii Vechi',
    'Bleșteni',
    'Brătușeni',
    'Brătușenii Noi',
    'Brînzeni',
    'Burlănești',
    'Buzdugeni',
    'Cepeleuți',
    'Chetroșica Nouă',
    'Chetroșica Veche',
    'Chiurt',
    'Clișcăuți',
    'Constantinovca',
    'Corpaci',
    'Cuconeștii Noi',
    'Cuconeștii Vechi',
    'Cupcini',
    'Edineț',
    'Fetești',
    'Fîntîna Albă',
    'Gașpar',
    'Goleni',
    'Gordinești',
    'Gordineștii Noi',
    'Hancăuți',
    'Hincăuți',
    'Hlinaia',
    'Hlinaia Mică',
    'Iachimeni',
    'Lopatnic',
    'Onești',
    'Parcova',
    'Poiana',
    'Rîngaci',
    'Rotunda',
    'Ruseni',
    'Slobodca',
    'Stolniceni',
    'Șofrîncani',
    'Terebna',
    'Tîrnova',
    'Trinca',
    'Vancicăuți',
    'Viișoara',
    'Volodeni',
    'Zăbriceni',
  ],
  'Fălești': [
    'Albinețul Nou',
    'Albinețul Vechi',
    'Beleuți',
    'Bocani',
    'Bocșa',
    'Burghelea',
    'Catranîc',
    'Călinești',
    'Călugăr',
    'Chetriș',
    'Chetrișul Nou',
    'Ciolacu Nou',
    'Ciolacu Vechi',
    'Ciuluc',
    'Comarovca',
    'Cuzmenii Vechi',
    'Doltu',
    'Drujineni',
    'Egorovca',
    'Fălești',
    'Făgădău',
    'Făleștii Noi',
    'Frumușica',
    'Glinjeni',
    'Hiliuți',
    'Hitrești',
    'Hîncești',
    'Hîrtop',
    'Horești',
    'Hrubna Nouă',
    'Ilenuța',
    'Ișcălău',
    'Ivanovca',
    'Izvoare',
    'Logofteni',
    'Lucăceni',
    'Măgura',
    'Măgura Nouă',
    'Măgureanca',
    'Mărăndeni',
    'Moldoveanca',
    'Musteața',
    'Natalievca',
    'Năvîrneț',
    'Nicolaevca',
    'Obreja Nouă',
    'Obreja Veche',
    'Pervomaisc',
    'Pietrosu',
    'Pietrosul Nou',
    'Pînzăreni',
    'Pînzărenii Noi',
    'Pîrlița',
    'Pocrovca',
    'Pompa',
    'Popovca',
    'Pruteni',
    'Răuțel',
    'Răuțelul Nou',
    'Rediul de Jos',
    'Rediul de Sus',
    'Risipeni',
    'Sărata Nouă',
    'Sărata Veche',
    'Scumpia',
    'Socii Noi',
    'Socii Vechi',
    'Suvorovca',
    'Șoltoaia',
    'Taxobeni',
    'Țapoc',
    'Unteni',
    'Valea Rusului',
    'Vrănești',
  ],
  'Florești': [
    'Alexandrovca',
    'Alexeevca',
    'Antonovca',
    'Băhrinești',
    'Bezeni',
    'Bobulești',
    'Bursuc',
    'Cașunca',
    'Căprești',
    'Cenușa',
    'Cernița',
    'Chirilovca',
    'Ciripcău',
    'Ciutulești',
    'Coșernița',
    'Cuhureștii de Sus',
    'Cuhureștii de Jos',
    'Cunicea',
    'Domulgeni',
    'Dumitreni',
    'Făgădău',
    'Florești',
    'Frumușica',
    'Frumușica Nouă',
    'Frunzești',
    'Ghindești',
    'Ghindești',
    'Gura Camencii',
    'Gura Căinarului',
    'Gvozdova',
    'Hîrtop',
    'Ion Vodă',
    'Iliciovca',
    'Ivanovca',
    'Izvoare',
    'Japca',
    'Lunga',
    'Maiscoe',
    'Mărinești',
    'Mărculești',
    'Mărculești',
    'Mihailovca',
    'Năpadova',
    'Nicolaevca',
    'Nicolaevca',
    'Octeabriscoe',
    'Prajila',
    'Prodănești',
    'Prodăneștii Vechi',
    'Putinești',
    'Rădulenii Noi',
    'Rădulenii Vechi',
    'Roșietici',
    'Roșieticii Vechi',
    'Sănătăuca',
    'Scăieni',
    'Sevirova',
    'Sîrbești',
    'Stîrceni',
    'Ștefănești',
    'Temeleuți',
    'Tîrgul Vertiujeni',
    'Trifănești',
    'Țipordei',
    'Țîra',
    'Unchitești',
    'Valea Rădoaiei',
    'Vărvăreuca',
    'Văscăuți',
    'Vertiujeni',
    'Zarojeni',
    'Zăluceni',
  ],
  'Glodeni': [
    'Balatina',
    'Bisericani',
    'Brînzeni',
    'Butești',
    'Cajba',
    'Camenca',
    'Camencuța',
    'Ciuciulea',
    'Clococenii Vechi',
    'Cobani',
    'Cot',
    'Cuhnești',
    'Danu',
    'Dușmani',
    'Fundurii Noi',
    'Fundurii Vechi',
    'Glodeni',
    'Hîjdieni',
    'Iabloana',
    'Limbenii Noi',
    'Limbenii Vechi',
    'Lipovăț',
    'Moara Domnească',
    'Molești',
    'Movileni',
    'Nicolaevca',
    'Petrunea',
    'Serghieni',
    'Soroca',
    'Stîrcea',
    'Sturzovca',
    'Tomeștii Noi',
    'Tomeștii Vechi',
    'Ustia',
    'Viișoara',
  ],
  'Hîncești': [
    'Anini',
    'Bălceana',
    'Bobeica',
    'Boghiceni',
    'Bozieni',
    'Brătianovca',
    'Bujor',
    'Buțeni',
    'Caracui',
    'Călmățui',
    'Cărpineni',
    'Cărpineanca',
    'Cățeleni',
    'Chetroșeni',
    'Cioara',
    'Ciuciuleni',
    'Cornești',
    'Coroliovca',
    'Costești',
    'Cotul Morii',
    'Crasnoarmeiscoe',
    'Dahnovici',
    'Dancu',
    'Drăgușeni',
    'Drăgușenii Noi',
    'Dubovca',
    'Feteasca',
    'Fîrlădeni',
    'Frasin',
    'Fundul Galbenei',
    'Hîncești',
    'Horjești',
    'Horodca',
    'Ivanovca',
    'Lăpușna',
    'Leușeni',
    'Logănești',
    'Marchet',
    'Mereșeni',
    'Mingir',
    'Mirești',
    'Negrea',
    'Nemțeni',
    'Obileni',
    'Onești',
    'Pașcani',
    'Pereni',
    'Pervomaiscoe',
    'Pogănești',
    'Rusca',
    'Sărata-Galbenă',
    'Sărata-Mereșeni',
    'Sărăteni',
    'Secăreni',
    'Secărenii Noi',
    'Semionovca',
    'Sofia',
    'Stolniceni',
    'Strîmbeni',
    'Șipoteni',
    'Tălăiești',
    'Valea Florii',
    'Voinescu',
  ],
  'Ialoveni': [
    'Alexandrovca',
    'Bardar',
    'Bălțați',
    'Budăi',
    'Cărbuna',
    'Cigîrleni',
    'Costești',
    'Dănceni',
    'Gangura',
    'Găureni',
    'Hansca',
    'Horești',
    'Homuteanovca',
    'Horodca',
    'Ialoveni',
    'Malcoci',
    'Mileștii Mici',
    'Mileștii Noi',
    'Misovca',
    'Molești',
    'Nimoreni',
    'Piatra Albă',
    'Pojăreni',
    'Puhoi',
    'Răzeni',
    'Ruseștii Noi',
    'Ruseștii Vechi',
    'Sociteni',
    'Suruceni',
    'Țipala',
    'Ulmu',
    'Văratic',
    'Văsieni',
    'Zîmbreni',
  ],
  'Leova': [
    'Băiuș',
    'Beștemac',
    'Borogani',
    'Bulgărica',
    'Cazangic',
    'Ceadîr',
    'Cîmpul Drept',
    'Cîzlar',
    'Cneazevca',
    'Cociulia Nouă',
    'Colibabovca',
    'Covurlui',
    'Cupcui',
    'Cuporani',
    'Filipeni',
    'Frumușica',
    'Hănăsenii Noi',
    'Hîrtop',
    'Iargara',
    'Leova',
    'Meșeni',
    'Nicolaevca',
    'Orac',
    'Pitești',
    'Romanovca',
    'Sărata Nouă',
    'Sărata-Răzeși',
    'Sărăteni',
    'Sărățica Nouă',
    'Sărățica Veche',
    'Seliște',
    'Sîrma',
    'Tigheci',
    'Tochile-Răducani',
    'Tomai',
    'Tomaiul Nou',
    'Troian',
    'Troița',
    'Victoria',
    'Vozneseni',
  ],
  'Nisporeni': [
    'Băcșeni',
    'Bălănești',
    'Bălăurești',
    'Bărboieni',
    'Boldurești',
    'Bolțun',
    'Brătuleni',
    'Bursuc',
    'Călimănești',
    'Chilișoaia',
    'Ciorești',
    'Ciutești',
    'Cîrnești',
    'Cristești',
    'Drojdieni',
    'Găureni',
    'Grozești',
    'Heleșteni',
    'Isăicani',
    'Iurceni',
    'Luminița',
    'Marinici',
    'Milești',
    'Mîrzoaia',
    'Nisporeni',
    'Odaia',
    'Odobești',
    'Păruceni',
    'Seliște',
    'Selișteni',
    'Soltănești',
    'Șendreni',
    'Șișcani',
    'Valea Nîrnovei',
    'Valea-Trestieni',
    'Vărzărești',
    'Vînători',
    'Vulcănești',
    'Zberoaia',
  ],
  'Ocnița': [
    'Berezovca',
    'Bîrlădeni',
    'Bîrnova',
    'Călărașeuca',
    'Clocușna',
    'Codreni',
    'Corestăuți',
    'Dîngeni',
    'Frunză',
    'Gîrbova',
    'Grinăuți',
    'Grinăuți',
    'Grinăuți-Moldova',
    'Grinăuți-Raia',
    'Hădărăuți',
    'Lencăuți',
    'Lipnic',
    'Maiovca',
    'Mereșeuca',
    'Mihălășeni',
    'Naslavcea',
    'Ocnița',
    'Ocnița',
    'Otaci',
    'Paladea',
    'Paustova',
    'Rediul Mare',
    'Rujnița',
    'Sauca',
    'Stălinești',
    'Unguri',
    'Vălcineț',
    'Verejeni',
  ],
  'Orhei': [
    'Andreevca',
    'Berezlogi',
    'Biești',
    'Bolohan',
    'Brănești',
    'Brăviceni',
    'Breanova',
    'Budăi',
    'Bulăiești',
    'Butuceni',
    'Camencea',
    'Chiperceni',
    'Cihoreni',
    'Ciocîlteni',
    'Cișmea',
    'Clișova',
    'Clișova Nouă',
    'Crihana',
    'Cucuruzeni',
    'Cucuruzenii de Sus',
    'Curchi',
    'Dișcova',
    'Donici',
    'Fedoreuca',
    'Furceni',
    'Ghetlova',
    'Hîjdieni',
    'Hulboaca',
    'Inculeț',
    'Isacova',
    'Ivancea',
    'Izvoare',
    'Jeloboc',
    'Jora de Jos',
    'Jora de Mijloc',
    'Jora de Sus',
    'Jeloboc',
    'Lopatna',
    'Lucășeuca',
    'Mana',
    'Mălăiești',
    'Mitoc',
    'Mîrzaci',
    'Mîrzești',
    'Morovaia',
    'Morozeni',
    'Neculăieuca',
    'Noroceni',
    'Ocnița-Răzeși',
    'Ocnița-Țărani',
    'Orhei',
    'Pelivan',
    'Peresecina',
    'Piatra',
    'Pocșești',
    'Podgoreni',
    'Pohorniceni',
    'Pohrebeni',
    'Puțintei',
    'Sămănanca',
    'Seliște',
    'Sirota',
    'Slobozia-Hodorogea',
    'Step-Soci',
    'Susleni',
    'Șercani',
    'Tabăra',
    'Teleșeu',
    'Tîrzieni',
    'Trebujeni',
    'Vatici',
    'Vîprova',
    'Vîșcăuți',
    'Voroteț',
    'Zahoreni',
    'Zorile',
  ],
  'Rezina': [
    'Boșernița',
    'Buciușca',
    'Bușăuca',
    'Cinișeuți',
    'Ciorna',
    'Cogîlniceni',
    'Cuizăuca',
    'Echimăuți',
    'Ghiduleni',
    'Gordinești',
    'Horodiște',
    'Ignăței',
    'Lalova',
    'Lipceni',
    'Mateuți',
    'Meșeni',
    'Mincenii de Jos',
    'Mincenii de Sus',
    'Nistreni',
    'Otac',
    'Păpăuți',
    'Peciște',
    'Pereni',
    'Piscărești',
    'Pripiceni-Curchi',
    'Pripiceni-Răzeși',
    'Rezina',
    'Roșcani',
    'Roșcanii de Jos',
    'Roșcanii de Sus',
    'Saharna',
    'Saharna Nouă',
    'Sîrcova',
    'Slobozia-Horodiște',
    'Solonceni',
    'Stohnaia',
    'Tarasova',
    'Trifești',
    'Țahnăuți',
    'Țareuca',
    'Țipova',
  ],
  'Rîșcani': [
    'Alexăndrești',
    'Aluniș',
    'Armanca',
    'Avrămeni',
    'Bălanu Nou',
    'Borosenii Noi',
    'Braniște',
    'Bulhac',
    'Cepăria',
    'Ciobanovca',
    'Corlăteni',
    'Costești',
    'Cucuieții Noi',
    'Cucuieții Vechi',
    'Dămășcani',
    'Druța',
    'Dumeni',
    'Duruitoarea',
    'Duruitoarea Nouă',
    'Gălășeni',
    'Grinăuți',
    'Hiliuți',
    'Horodiște',
    'Ivănești',
    'Lupăria',
    'Malinovscoe',
    'Mălăiești',
    'Mihăileni',
    'Mihăilenii Noi',
    'Moșeni',
    'Nihoreni',
    'Păscăuți',
    'Petrușeni',
    'Pîrjota',
    'Pociumbăuți',
    'Pociumbeni',
    'Proscureni',
    'Răcăria',
    'Rămăzan',
    'Recea',
    'Reteni',
    'Reteni-Vasileuți',
    'Rîșcani',
    'Singureni',
    'Slobozia-Recea',
    'Sturzeni',
    'Sverdiac',
    'Șaptebani',
    'Știubeieni',
    'Șumna',
    'Ușurei',
    'Vasileuți',
    'Văratic',
    'Zăicani',
  ],
  'Sîngerei': [
    'Alexăndreni',
    'Alexeuca',
    'Antonovca',
    'Bălășești',
    'Bilicenii Noi',
    'Bilicenii Vechi',
    'Biruința',
    'Bobletici',
    'Bocancea-Schit',
    'Brejeni',
    'Bursuceni',
    'Chirileni',
    'Chișcăreni',
    'Ciuciuieni',
    'Clișcăuți',
    'Coada Iazului',
    'Copăceni',
    'Coșcodeni',
    'Cotiujenii Mici',
    'Cotovca',
    'Cozești',
    'Cubolta',
    'Dobrogea Nouă',
    'Dobrogea Veche',
    'Drăgănești',
    'Dumbrăvița',
    'Evghenievca',
    'Flămînzeni',
    'Gavrilovca',
    'Grigorăuca',
    'Grigorești',
    'Gura-Oituz',
    'Heciul Nou',
    'Heciul Vechi',
    'Iezărenii Noi',
    'Iezărenii Vechi',
    'Izvoare',
    'Lipovanca',
    'Mărășești',
    'Mărinești',
    'Mihailovca',
    'Mîndreștii Noi',
    'Nicolaevca',
    'Octeabriscoe',
    'Pălăria',
    'Pepeni',
    'Pepenii Noi',
    'Petrovca',
    'Petropavlovca',
    'Prepelița',
    'Rădoaia',
    'Răzălăi',
    'Romanovca',
    'Sacarovca',
    'Sîngerei',
    'Sîngereii Noi',
    'Slobozia-Chișcăreni',
    'Slobozia-Măgura',
    'Sloveanca',
    'Șestaci',
    'Tăura Nouă',
    'Tăura Veche',
    'Trifănești',
    'Țambula',
    'Țiplești',
    'Țipletești',
    'Valea lui Vlad',
    'Valea Norocului',
    'Vladimireuca',
    'Vrănești',
  ],
  'Soroca': [
    'Alexandru cel Bun',
    'Balinți',
    'Balinții Noi',
    'Bădiceni',
    'Băxani',
    'Bulboci',
    'Bulbocii Noi',
    'Căinarii Vechi',
    'Cerlina',
    'Cosăuți',
    'Cremenciug',
    'Cureșnița',
    'Cureșnița Nouă',
    'Dărcăuți',
    'Dărcăuții Noi',
    'Decebal',
    'Dubna',
    'Dumbrăveni',
    'Egoreni',
    'Floriceni',
    'Grigorăuca',
    'Holoșnița',
    'Hristici',
    'Iarova',
    'Iorjnița',
    'Inundeni',
    'Livezi',
    'Lugovoe',
    'Mălcăuți',
    'Nimereuca',
    'Niorcani',
    'Oclanda',
    'Ocolina',
    'Parcani',
    'Pîrlița',
    'Racovăț',
    'Redi-Cereșnovăț',
    'Regina Maria',
    'Rublenița',
    'Rublenița Nouă',
    'Ruslanovca',
    'Rudi',
    'Schineni',
    'Schinenii Noi',
    'Slobozia-Cremene',
    'Slobozia Nouă',
    'Slobozia-Vărăncău',
    'Sobari',
    'Soloneț',
    'Soroca',
    'Stoicani',
    'Șeptelici',
    'Șolcani',
    'Tătărăuca Veche',
    'Tolocănești',
    'Trifăuți',
    'Țepilova',
    'Valea',
    'Vanțina',
    'Vanțina Mică',
    'Vasilcău',
    'Vărăncău',
    'Vădeni',
    'Visoca',
    'Voloave',
    'Volovița',
    'Zastînca',
  ],
  'Strășeni': [
    'Bucovăț',
    'Căpriana',
    'Chirianca',
    'Ciobanca',
    'Codreanca',
    'Cojușna',
    'Dolna',
    'Drăgușeni',
    'Făgureni',
    'Gălești',
    'Găleștii Noi',
    'Ghelăuza',
    'Gornoe',
    'Greblești',
    'Huzun',
    'Lozova',
    'Lupa-Recea',
    'Mărtinești',
    'Micăuți',
    'Micleușeni',
    'Negrești',
    'Onești',
    'Pănășești',
    'Rassvet',
    'Rădeni',
    'Romănești',
    'Recea',
    'Roșcani',
    'Saca',
    'Scoreni',
    'Sireți',
    'Stejăreni',
    'Strășeni',
    'Tătărești',
    'Țigănești',
    'Voinova',
    'Vorniceni',
    'Zamciogi',
    'Zubrești',
  ],
  'Șoldănești': [
    'Alcedar',
    'Chipeșca',
    'Climăuții de Jos',
    'Cobîlea',
    'Cot',
    'Cotiujenii Mari',
    'Curătura',
    'Cușelăuca',
    'Cușmirca',
    'Dobrușa',
    'Fuzăuca',
    'Găuzeni',
    'Glinjeni',
    'Lelina',
    'Mihuleni',
    'Odaia',
    'Olișcani',
    'Parcani',
    'Pohoarna',
    'Poiana',
    'Răspopeni',
    'Recești',
    'Rogojeni',
    'Salcia',
    'Sămășcani',
    'Socola',
    'Șestaci',
    'Șestaci',
    'Șipca',
    'Șoldănești',
    'Vadul-Rașcov',
    'Zahorna',
  ],
  'Ștefan Vodă': [
    'Alava',
    'Antonești',
    'Brezoaia',
    'Carahasani',
    'Căplani',
    'Cioburciu',
    'Copceac',
    'Crocmaz',
    'Ermoclia',
    'Feștelița',
    'Lazo',
    'Marianca de Jos',
    'Olănești',
    'Palanca',
    'Popeasca',
    'Purcari',
    'Răscăieți',
    'Răscăieții Noi',
    'Semionovca',
    'Slobozia',
    'Ștefan Vodă',
    'Ștefănești',
    'Talmaza',
    'Tudora',
    'Viișoara',
    'Volintiri',
  ],
  'Taraclia': [
    'Albota de Jos',
    'Albota de Sus',
    'Aluatu',
    'Balabanu',
    'Budăi',
    'Cairaclia',
    'Cealîc',
    'Ciumai',
    'Chirilovca',
    'Corten',
    'Cortenul Nou',
    'Dermengi',
    'Hagichioi',
    'Hîrtop',
    'Mirnoe',
    'Musaitu',
    'Novosiolovca',
    'Orehovca',
    'Roșița',
    'Salcia',
    'Samurza',
    'Sofievca',
    'Taraclia',
    'Tvardița',
    'Valea Perjei',
    'Vinogradovca',
  ],
  'Telenești': [
    'Bănești',
    'Băneștii Noi',
    'Bogzești',
    'Bondareuca',
    'Brînzenii Noi',
    'Brînzenii Vechi',
    'Budăi',
    'Căzănești',
    'Chersac',
    'Chiștelnița',
    'Chițcanii Noi',
    'Chițcanii Vechi',
    'Ciofu',
    'Ciulucani',
    'Cîșla',
    'Codru',
    'Codrul Nou',
    'Coropceni',
    'Crăsnășeni',
    'Cucioaia',
    'Cucioaia Nouă',
    'Dobrușa',
    'Flutura',
    'Ghermănești',
    'Ghiliceni',
    'Hirișeni',
    'Hîrtop',
    'Inești',
    'Leușeni',
    'Mihălașa',
    'Mihălașa Nouă',
    'Mîndra',
    'Mîndrești',
    'Negureni',
    'Nucăreni',
    'Ordășei',
    'Pistruieni',
    'Pistruienii Noi',
    'Ratuș',
    'Sărătenii Noi',
    'Sărătenii Vechi',
    'Scorțeni',
    'Suhuluceni',
    'Telenești',
    'Tîrșiței',
    'Țînțăreni',
    'Vadul-Leca',
    'Vadul-Leca Nou',
    'Văsieni',
    'Verejeni',
    'Zahareuca',
    'Zăicani',
    'Zăicanii Noi',
    'Zgărdești',
  ],
  'Ungheni': [
    'Agronomovca',
    'Alexeevca',
    'Blindești',
    'Boghenii Noi',
    'Boghenii Vechi',
    'Buciumeni',
    'Bulhac',
    'Bumbăta',
    'Bușila',
    'Buzduganii de Jos',
    'Buzduganii de Sus',
    'Cetireni',
    'Chirileni',
    'Cioropcani',
    'Condrătești',
    'Cornești',
    'Cornești',
    'Cornova',
    'Costuleni',
    'Coșeni',
    'Curtoaia',
    'Drujba',
    'Elizavetovca',
    'Floreni',
    'Florești',
    'Florițoaia Nouă',
    'Florițoaia Veche',
    'Frăsinești',
    'Gherman',
    'Grăseni',
    'Grozasca',
    'Hîrcești',
    'Hristoforovca',
    'Izvoreni',
    'Leordoaia',
    'Lidovca',
    'Măcărești',
    'Măgurele',
    'Mănoilești',
    'Medeleni',
    'Mircești',
    'Mînzătești',
    'Morenii Noi',
    'Morenii Vechi',
    'Năpădeni',
    'Negurenii Noi',
    'Negurenii Vechi',
    'Novaia Nicolaevca',
    'Petrești',
    'Pîrlița',
    'Poiana',
    'Pojarna',
    'Rădenii Vechi',
    'Rezina',
    'Romanovca',
    'Sculeni',
    'Săghieni',
    'Semeni',
    'Sinești',
    'Stolniceni',
    'Șicovăț',
    'Teșcureni',
    'Todirești',
    'Țîghira',
    'Ungheni',
    'Unțești',
    'Valea Mare',
    'Veverița',
    'Vulpești',
    'Zagarancea',
    'Zăzulenii Noi',
    'Zăzulenii Vechi',
  ]
};
