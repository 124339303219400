import React from 'react';
import Menu from './menu.component';
import { Link } from 'react-router-dom';
import Logo from './logo-dark.png';
import { Search } from './search.component';

export const Header = ({ cartCount, header, unmountProducts }) => {

  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  return (
    <div className="nav-container" id="nav-container">
      <div className="bar bar--sm visible-xs mobile-only">
        <div className="container">
          <div className="row">
            <div className="col-3 col-md-2">
              <Link to="/">
                <img className="logo logo-dark" alt="logo" src={Logo} />
              </Link>
            </div>
            <div className="col-9 col-md-10 text-right">
              <a href="/cart" className="shopping-basket">
                <span className="shopping-basket-count">{cartCount}</span>
                <i className="icon icon-Shopping-Basket color--primary" />
              </a>

              <a href="#" className="hamburger-toggle" onClick={() => setShowMobileMenu(!showMobileMenu)} data-toggle-class="#menu1;hidden-xs">
                <i className="icon icon--sm stack-interface stack-menu" />
              </a>
            </div>
          </div>

          {showMobileMenu && <Menu menu={header.menu} loaded={header.loaded} unmountProducts={unmountProducts} hideMenu={() => setShowMobileMenu(false)} />}
        </div>
      </div>

      <nav id="menu0" className="bar bar--sm bar-1 hidden-xs top-menu">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 hidden-xs">
              <div className="bar__module">
                <Link to="/">
                  <img className="logo logo-dark" alt="logo" src={Logo} />
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <Search />
            </div>
            <div className="col-lg-1 col-md-1 text-right">
              <Link to="/cart" className="shopping-basket">
                <span className="shopping-basket-count">{cartCount}</span>
                <i className="icon icon-Shopping-Basket color--primary" />
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <nav id="menu1" className="bar bar--sm bar-1 hidden-xs fixed-menu" data-scroll-class="82px:pos-fixed">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="bar__module">

                <Menu menu={header.menu} loaded={header.loaded} unmountProducts={unmountProducts} />

                <a href="#" className="shopping-basket">
                  <span className="shopping-basket-count">{cartCount}</span>
                  <i className="icon icon-Shopping-Basket color--primary" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
