import React from 'react';

export const FooterLoading = () => {

	return (
		<footer className="space--sm footer-2 bg--secondary">
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-lg-3 col-6">
						<div className="loading-line" />
						<div className="loading-line" />
						<div className="loading-line" />
						<div className="loading-line" />
					</div>
					<div className="col-md-6 col-lg-4 col-6">
						<div className="loading-line" />
						<div className="loading-line" />
						<div className="loading-line" />
						<div className="loading-line" />
					</div>
					<div className="col-md-6 col-lg-5 col-6">
						<div className="loading-line" />
						<div className="loading-line" />
						<div className="loading-line" />
						<div className="loading-line" />
					</div>
				</div>
			</div>
		</footer>
	);
};
