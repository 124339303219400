import React from 'react';
import { hydrate, render } from "react-dom";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import App from './app/index';
import ReactGA from 'react-ga';

const trackingId = "UA-162908051-1";
ReactGA.initialize(trackingId);

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

import 'sanitize.css/sanitize.css';
import './index.scss';

const target = document.querySelector('#root');

window['craftioImages'] = {};

const _func = target && target.hasChildNodes() ? hydrate : render;

_func(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>,
	target
);
