import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProductList from './product-list.component';
import { fetchCategoryProducts, UNLOAD_CATEGORY_PRODUCTS } from './product-list.actions';
import { AppState } from '../root-reducer';

export const mapStateToProps = ({ productList, header }: AppState) => {
	return {
    productList
	};
};

export const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		fetchCategoryProducts,
		unmount: () => dispatch({ type: UNLOAD_CATEGORY_PRODUCTS })
	}, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductList);
