import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { PrimaryRadio, ErrorRadio } from '../common/form.components';
import { OptionalField } from './product.state';

export default class extends Component {

    public props: any;
    public myRef: any;
    public fields = {
        Text: this.renderText.bind(this),
        Image: this.renderImage.bind(this),
        Select: this.renderSelect.bind(this),
        Radio: this.renderRadio.bind(this)
    };

    public state = { limit: 200, fileName: 'Încarcă imagine', imageLoaded: false };

    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.onChange = this.onChange.bind(this);
        this.onImageSelect = this.onImageSelect.bind(this);
        this.discardImage = this.discardImage.bind(this);
    }

    public componentDidMount() {
        const field: OptionalField = this.props.field;

        if (field.limit) {
            this.setState({ limit: field.limit });
        }
    }

    public renderText(field: OptionalField, valid: boolean) {
        return (
            <div className={`optional-field ${valid ? '' : 'field-error'}`}>
                <label>{field.name}:</label>
                <input type="text" name={field.name} placeholder={field.placeholder || ''} onChange={this.onChange} maxLength={field.limit || 999} />
                {field.limit ? <span className="input-limit">{this.state.limit}</span> : null}
            </div>
        );
    }

    public renderImage(field: OptionalField, valid: boolean) {
        return (
            <div className={`optional-field ${valid ? '' : 'field-error'}`}>
              <label>{field.name}:</label> <br/>
              <a className={`btn btn--sm btn--secondary btn--icon upload-image-btn ${this.state.imageLoaded ? 'image-loaded' : ''}`}>
                <span className="btn__text">
                  <i className="icon icon-Add-File" onClick={() => this.myRef.current.click()} />
                  {this.state.fileName}
                </span>
                {this.state.imageLoaded && <span className="discard-image-btn" onClick={this.discardImage}>✕</span>}
              </a>
              <input ref={this.myRef} style={{display: 'none'}} type="file" name="pic" accept="image/png, image/jpeg, image/jpg" onChange={this.onImageSelect} />
            </div>
        );
    }

    public renderSelect(field: OptionalField, valid: boolean) {
        return (
            <div className={`optional-field ${valid ? '' : 'field-error'}`}>
              <br />
              <div className="input-select">
                <select name={field.name} onChange={this.onChange} defaultValue="" >
                  <option value="" disabled>{field.name}</option>
                  {field.options && field.options.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>
        );
    }

    public renderRadio(field: OptionalField, valid: boolean) {
        const RadioComponent = valid ? PrimaryRadio : ErrorRadio;
        return (
            <div className={`optional-field ${valid ? '' : 'field-error'}`}>
                <label>{field.name}:</label>
                <RadioGroup aria-label={field.name} name={field.name} onChange={this.onChange}>
                    {field.options && field.options.map(option => (
                        <FormControlLabel key={option} value={option} control={<RadioComponent />} label={option} />
                    ))}
                </RadioGroup>
            </div>
        );
    }

    public onImageSelect({ target }) {
      const field: OptionalField = this.props.field;

      if (target.files.length === 0) {
        delete window['craftioImages'][field.name];
        this.props.onChange({ name: field.name, value: '' });
      }

      window['craftioImages'][field.name] = target.files[0];
      this.props.onChange({ name: field.name, value: target.files[0].name });
      this.setState({ fileName: target.files[0].name, imageLoaded: true })
    }

    public discardImage({ target }) {
      const field: OptionalField = this.props.field;

      this.myRef.current.value = '';
      delete window['craftioImages'][field.name];
      this.props.onChange({ name: field.name, value: '' });
      this.setState({ fileName: 'Încarcă imagine', imageLoaded: false })
    }

    public onChange({ target: { value, name } }) {
        const field: OptionalField = this.props.field;

        if (field.limit) {
            this.setState({ limit: field.limit - value.length });

            if (field.limit - value.length < 0) {
                return;
            }
        }

        this.props.onChange({ name, value });
    }

    public render() {
		const field: OptionalField = this.props.field;

        return this.fields[field.type](field, this.props.valid);
    }
}
