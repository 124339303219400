import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import apiService from '../common/api.service';

interface SearchProduct {
  image: string;
  name: string;
  id: string;
  url: string;
  price: number;
}

export class Search extends Component {

  public input: any;
  public timeout: any = null;
  public delay: number = 1000;
  public state = {
    isActive: false,
    isTypeing: false,
    isLoading: false,
    results: [],
    noResults: false
  };

  constructor(props) {
    super(props);

    this.input = React.createRef();

    this.setActive = this.setActive.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onResultClick = this.onResultClick.bind(this);
  }

  private setActive(isActive: boolean) {
    if (!isActive) {
      this.input.current.value = '';
    }

    this.setState({
      isActive,
      results: [],
      noResults: false
    });
  }
  
  private onKeyUp() {
    clearTimeout(this.timeout);
    this.setState({ isTypeing: true, noResults: false });
    
    if (this.input.current.value.length === 0) {
      this.input.current.blur();
      this.setActive(false);
    }

    this.timeout = setTimeout(() => {
      this.setState({ isTypeing: false, isLoading: true });
      this.searchProducts(this.input.current.value).then(results => {
        this.setState({
          results,
          isLoading: false,
          noResults: results.length === 0
        });
      });

    }, this.delay);
  }

  searchProducts(query: string): Promise<SearchProduct[]> {
    return new Promise((resolve, reject) => {
      apiService.headerSearch(query)
        .then(data => resolve(data.headerSearch.map(res => ({
          image: res.photos[0].url,
          name: res.name,
          url: res.url,
          price: res.price,
          id: res.id
        })))).catch(reject);
    });
  }

  onResultClick(event, id: string) {
    this.setActive(false);
    event.stopPropagation();

    // onBlur={() => this.setActive(false)}
  }

  render() {
    const { isActive, isTypeing, isLoading, results, noResults } = this.state;
    const showLoader = isTypeing || isLoading;

    return (
      <div className={`input-icon search-input ${isActive ? 'active' : ''}`}>
        <i className="material-icons">search</i>
        <input ref={this.input} type="text" name="input" placeholder="Cauta produs..." onKeyUp={this.onKeyUp} onFocus={() => this.setActive(true)}  />
        { isActive && <div className="search-results container">
          { (results.length > 0 && !showLoader) && results.map((product: SearchProduct) => (
            <Link to={`/product/${product.url}`} className="search-result row" key={product.id} onClick={(e) => this.onResultClick(e, product.id)}>
              <div className="search-result__image col-4">
                <img src={product.image} />
              </div>
              <div className="search-result__details col-8">
                <h4>{product.name}</h4>
                <span>{product.price} Lei</span>
              </div>
            </Link>
          ))}

          {showLoader && (
            <div className="search-result row loading-mode">
              <div className="search-result__image col-4">
                <div className="loading" />
              </div>
              <div className="search-result__details col-8">
                <div className="loading" />
                <div className="loading" />
                <div className="loading" />
              </div>
            </div>
          )}

          {noResults && (
            <div className="search-result row no-results">
              <strong>Nu sunt rezultate</strong>
            </div>
          )}
        </div>}
      </div>
    );
  }
}
