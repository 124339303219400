import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RadioGroup from '@material-ui/core/RadioGroup';
import { PrimaryRadio } from '../../common/form.components';
import PaymentsImage from '../../footer/payment.jpg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import apiService from '../../common/api.service';
import { withRouter } from 'react-router-dom';
import { goToTop } from '../../common/helpers';

type MyProps = any;
type MyState = any;
class Delivery extends React.Component<MyProps, MyState> {

  public state = { valid: false, submited: false, loading: false };

  public mediaPath = 'https://media.craftio.md/optional-fields';

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.addOrder = this.addOrder.bind(this);
  }

  componentDidMount() {
    this.props.updateDeliveryInfo({ region: this.props.cart.delivery.region || this.regions[0] });
    this.props.updateDeliveryInfo({ city: this.props.cart.delivery.city || this.cities[0] });
    goToTop();
  }

  public onChange(event: any) {
		const { name, value } = event.target;
		this.props.updateDeliveryInfo({ [name]: value });
  };

  public get regions(): string[] {
    return Object.keys(this.props.cart.regions);
  }

  public get cities(): string[] {
    const { cart } = this.props;

    return cart.regions[cart.delivery.region || Object.keys(cart.regions)[0]];
  }

  public get freeDeliveryLocations(): string[] {
    return ['Chișinău', 'Băcioi'];
  }

  public addOrder() {
    let isFormValid = true;
    const validationInputs = ['name', 'phone', 'street', 'payment'];

    this.setState({ valid: isFormValid, submited: true, loading: true }, () => {
      validationInputs.forEach(input => {
        isFormValid = isFormValid && this.validate(input);
      });

      if (isFormValid) {
        apiService.addOrder(this.props.cart, !!this.isFreeDelivery).then((data) => {
          this.props.history.push('/cart/confirmation');

          if (this.props.cart.delivery.payment === 'Online') {
            const win = window.open("/cart.php?total=" + this.totalOrderPrice + "&order=" + data.id, '_blank');
            if (win) {
              win.focus();
            }
          }

          this.props.completeOrder();
        });
      } else {
        this.setState({ ...this.state, loading: false });
        goToTop();
      }
    });
  }

  public validate(name: string): boolean {
    const { cart: { delivery } } = this.props;
    const { submited } = this.state;

    return !submited || (submited && (!!delivery[name] && !!delivery[name].trim().length));
  }

  public get isFreeDelivery(): boolean {
    const { cart } = this.props;

    const isOnlinePayment = cart.delivery.payment === 'Online';
    const isFreeDeliveryLocation = this.freeDeliveryLocations.includes(cart.delivery.city);

    return isOnlinePayment || isFreeDeliveryLocation;
  }

  public get totalDeliveryPrice(): number {
    const { cart } = this.props;

    return this.isFreeDelivery ? 0 : cart.items.reduce((r, i) => r + (i.quantity * i.deliveryPrice), 0);
  }

  public get totalOrderPrice(): number {
    const { cart } = this.props;

    return this.totalDeliveryPrice + cart.items.reduce((r, i) => r + (i.quantity * i.price), 0);
  }

  render() {
    const { cart } = this.props;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    return (
      <section className="container cart-component">
        <Helmet>
          <title>Detalii de livrare - Craftio</title>
        </Helmet>
        <div className="row">
          <div className="col-md-12 page-title">
            <h1>Detalii de livrare</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="boxed boxed--border">

              <div className="row">
                <div className={`col-md-6 col-sm-12 ${this.validate('name') ? '' : 'error-field'}`}>
                  <label>Nume, Prenume: <span className="required">*</span></label>
                  <input type="text" name="name" onChange={this.onChange} defaultValue={cart.delivery.name} />
                </div>
                <div className={`col-md-6 col-sm-12 ${this.validate('phone') ? '' : 'error-field'}`}>
                  <label>Telefon: <span className="required">*</span></label>
                  <input type="text" name="phone" onChange={this.onChange} defaultValue={cart.delivery.phone}  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <label>Raion: <span className="required">*</span></label>
                  <div className="input-select">
                    <select onChange={this.onChange} name="region" defaultValue={cart.delivery.region} >
                      {this.regions.map(region => <option key={region} value={region}>{region}</option>)}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label>Localitate: <span className="required">*</span></label>
                  <div className="input-select">
                    <select onChange={this.onChange} name="city" defaultValue={cart.delivery.city} >
                      {this.cities.map(city => <option key={city} value={city}>{city}</option>)}
                    </select>
                  </div>
                </div>
                <div className={`col-12 ${this.validate('street') ? '' : 'error-field'}`}>
                  <label>Strada: <span className="required">*</span></label>
                  <input onChange={this.onChange} type="text" name="street" defaultValue={cart.delivery.street}  />
                </div>
                <div className="col-12">
                  <label>Alte detalii:</label>
                  <textarea onChange={this.onChange} name="details" defaultValue={cart.delivery.details}  />
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-12">
                <h3>Metoda de plată</h3>
              </div>
              <div className="col-md-12">
                <div className="boxed boxed--border">
                  <div className="row">
                    <div className={`col-12 ${this.validate('payment') ? '' : 'error-field'}`}>
                      <RadioGroup aria-label="Metoda de plată" name="payment" onChange={this.onChange} value={cart.delivery.payment}>
                        <FormControlLabel value="Numerar" control={<PrimaryRadio />} label="Numerar - Vei plăti în momentul în care comanda va fi livrată." />
                        <FormControlLabel value="Online" control={<PrimaryRadio />} label="Online - Vei plăti cu cardul imediat ce trimiți comanda" />
                        {cart.delivery.payment === 'Online' && <img style={{ width: 'max-content' }} src={PaymentsImage} alt="Payment methods"/>}
                      </RadioGroup>

                      <hr/>

                      {!isMobile && <table className="border--round desktop-version">
                          <thead>
                            <tr>
                              <th style={{ minWidth: '120px', textAlign: 'left'}}>Produs</th>
                              <th style={{ width: '120px', textAlign: 'center'}}>Cantitate</th>
                              <th style={{ width: '120px', textAlign: 'center'}}>Preț / buc.</th>
                              <th style={{ width: '150px', textAlign: 'center'}}>Preț livrare / buc.</th>
                              <th style={{ width: '120px', textAlign: 'center'}}>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart.items.map((item, i) => <tr key={item.id + i} className="list-tr">
                              <td><strong>{item.name}</strong> ({
                                Object.values(item.optionalFields).map((property: any, index: number, data: any[]) => {
                                  if (property.startsWith(this.mediaPath)) {
                                    return (<span key={index}><a target="_blank" href={property}>Imagine</a>, </span>);
                                  } else {
                                  return (<span key={index}>{property}{index < data.length - 1 ? ', ' : ''}</span>);
                                  }
                                })
                              })</td>
                              <td>{item.quantity}</td>
                              <td>{item.price} Lei</td>
                              <td>{this.isFreeDelivery ? '-' : `${item.deliveryPrice} Lei`}</td>
                              <td>{item.quantity * item.deliveryPrice + item.quantity * item.price} Lei</td>
                            </tr>)}

                            <tr className="totals-tr">
                              <td colSpan={3}><strong>Totaluri</strong></td>
                              <td>
                                <span className="delivery-span">Preț total livrare</span>
                                <strong>{this.isFreeDelivery ? 'GRATUIT' : `${this.totalDeliveryPrice} Lei` }</strong>
                              </td>
                              <td className="last-one">
                                <span className="totals-span">Total de plată</span>
                                <strong>{this.totalOrderPrice} Lei</strong>
                              </td>
                            </tr>
                          </tbody>
                      </table>}

                      {isMobile && <table className="border--round mobile-version">
                          <thead>
                            <tr>
                              <th style={{ minWidth: '120px', textAlign: 'left'}} colSpan={5}>Produse</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart.items.map((item, i) => (
                              <tr key={item.id + i + 'main'} className="list-tr">
                                <td colSpan={5}>
                                  <strong>{item.name}</strong> ({
                                    Object.values(item.optionalFields).map((property: any, index: number, data: any[]) => {
                                      if (property.startsWith(this.mediaPath)) {
                                        return (<span key={index}><a target="_blank" href={property}>Imagine</a>, </span>);
                                      } else {
                                      return (<span key={index}>{property}{index < data.length - 1 ? ', ' : ''}</span>);
                                      }
                                    })
                                  })
                                  <br/>
                                  <div><strong>Cantitate: </strong><span>{item.quantity}</span></div>
                                  <div><strong>Preț / buc.: </strong><span>{item.price} Lei</span></div>
                                  <div><strong>Preț livrare / buc.: </strong><span>{this.isFreeDelivery ? '-' : `${item.deliveryPrice} Lei`}</span></div>
                                  <div><strong>Total: </strong><span>{item.quantity * item.deliveryPrice + item.quantity * item.price} Lei</span></div>
                                </td>
                              </tr>
                            ))}

                            <tr className="totals-tr">
                              <td colSpan={3}><strong>Totaluri</strong></td>
                              <td>
                                <span className="delivery-span">Preț total livrare</span>
                                <strong>{this.isFreeDelivery ? 'GRATUIT' : `${this.totalDeliveryPrice} Lei` }</strong>
                              </td>
                              <td className="last-one">
                                <span className="totals-span">Total de plată</span>
                                <strong>{this.totalOrderPrice} Lei</strong>
                              </td>
                            </tr>
                          </tbody>
                      </table>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-end">
              <div className="col-lg-2 text-center-xs">
                <Link to="/cart">
                  <button type="submit" className="btn btn--secondary">Inapoi</button>
                </Link>
              </div>
              <div className="col-lg-3 text-right text-center-xs">
                <button type="submit" className="btn btn--success btn--lg" onClick={this.addOrder} disabled={this.state.loading}>Finalizează comanda »</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Delivery);
