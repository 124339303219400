export interface FooterState {
	loaded: any;
	constants: any;
	pages: any;
}

export const footerInitialState: FooterState = {
	loaded: false,
	constants: {},
	pages: {}
};
