import apiService from "../common/api.service";

export const LOAD_FOOTER = 'LOAD_FOOTER';
export const fetchFooter = () => {
	return dispatch => {
		apiService.fetchFooterData().then(data => {
			dispatch({ type: LOAD_FOOTER, data });
		});
	};
};
