interface Slider {
	image: string;
	link: string | null;
}

interface FeaturedItems {
	id: string;
	name: string;
	image: string;
	price: number;
}

export interface HomeState {
	loaded: boolean;
	sliders: Slider[];
	featuredItems: FeaturedItems[];
}

export const homeState: HomeState = {
	loaded: false,
	sliders: [],
	featuredItems: []
};
