import apiService from '../common/api.service';

export const UNLOAD_CATEGORY_PRODUCTS = 'UNLOAD_CATEGORY_PRODUCTS';
export const LOAD_CATEGORY_PRODUCTS = 'LOAD_CATEGORY_PRODUCTS';
export const FETCH_CATEGORY_PRODUCTS = 'FETCH_CATEGORY_PRODUCTS';
export const fetchCategoryProducts = (menu: string, category: string, subcategory: string) => {
	return dispatch => {
		apiService.getCategoryProducts(menu, category, subcategory).then(data => {
			dispatch({ type: LOAD_CATEGORY_PRODUCTS, data });
		});
	};
};
