export interface MenuItem {
	id: string;
	name: string;
	children?: MenuItem[];
}

export interface HeaderState {
	loaded: boolean;
	menu: MenuItem[];
	navigation: any;
}

export const HeaderState: HeaderState = {
	loaded: false,
	menu: [],
	navigation: {}
};
