import { footerInitialState, FooterState } from './footer.state';
import { LOAD_FOOTER } from './footer.actions';

export default (state: FooterState = footerInitialState, action) => {

	switch (action.type) {
		case LOAD_FOOTER:
			return loadFooterData(state, action);

		default:
			return state;
	}
};

const loadFooterData = (state: FooterState, action: any) => {
  const newState = { ...state };
  
	newState.constants = action.data.constants.reduce((red, item) => ({ ...red, [item.key]: item }), {});
	newState.pages = action.data.pages;
	newState.loaded = true;

	return newState;
};
