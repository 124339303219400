export const goToTop = () => {
  const root = document.querySelector('#root');
  if (root) {
    root.scrollIntoView({ behavior: 'smooth' });
  }
}

export const urlSerialize = (name: string) => {
  return name.toLocaleLowerCase()
              .replace(/ - /gi, '-')
              .replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '')
              .replace(/  /gi, ' ')
              .replace(/ă/gi, 'a')
              .replace(/ț/gi, 't')
              .replace(/ţ/gi, 't')
              .replace(/î/gi, 'i')
              .replace(/â/gi, 'a')
              .replace(/ș/gi, 's')
              .replace(/ş/gi, 's')
              .replace(/ /gi, '-');
}