import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Pages from './pages.component';
import { AppState } from '../root-reducer';

export const mapStateToProps = ({ footer }: AppState) => footer;

export const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Pages);
