import { LOAD_CATEGORY_PRODUCTS, UNLOAD_CATEGORY_PRODUCTS } from './product-list.actions';
import { productListInitialState, ProductListState } from './product-list.state';

export default (state: ProductListState = productListInitialState, action) => {
	switch (action.type) {

		case LOAD_CATEGORY_PRODUCTS:
			return loadProducts(state, action);

		case UNLOAD_CATEGORY_PRODUCTS:
			return unloadProducts(state, action);

		default:
			return state;
	}
};

const unloadProducts = (state: ProductListState, action: any) => {
	const newState = { ...state };

	newState.category = null;
	newState.subcategory = null;
	newState.products = null;
	newState.loaded = false;

	return newState;
};

const loadProducts = (state: ProductListState, action: any) => {
	const newState = { ...state };

	newState.loaded = true;
	newState.category = action.data.categories ? action.data.categories[0].name : '-';
	newState.subcategory = action.data.subcategories ? action.data.subcategories[0] : {};
	newState.products = action.data.searchByCategories.map(product => ({
		id: product.id,
		url: product.url,
		name: product.name,
		price: product.price,
		photo: product.photos[0].url,
	}));

	return newState;
};
