interface ListProduct {
	id: string;
	name: string;
	photo: string;
	price: number;
}

export interface ProductListState {
	loaded: boolean;
	products: null | ListProduct[];
	category: null | string;
	subcategory: null | {
		name: string;
		description: string;
	};
}

export const productListInitialState: ProductListState = {
	loaded: false,
	products: null,
	category: null,
	subcategory: null
};
