import Moldova from './moldova';

export interface CartState {
	items: CartItem[];
  delivery: Partial<DeliveryInfo>;
  regions: any;
}

export interface CartItem {
	id: string;
	name: string;
	optionalFields: any;
	photo: string;
	price: number;
	quantity: number;
	deliveryPrice: number;
}

export interface DeliveryInfo {
	name: string;
	phone: string;
	region: string;
	city: string;
	street: string;
	details: string;
	payment: string;
}

const sessionStorageCart = JSON.parse(sessionStorage.getItem('craftio-cart') || '{}');

export const cartState: CartState = {
	items: sessionStorageCart.items || [],
  delivery: sessionStorageCart.delivery || {},
  regions: Moldova
};
