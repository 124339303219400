import { combineReducers } from 'redux';
import homeReducer from './home/home.reducer';
import cartReducer from './cart/cart.reducer';
import headerReducer from './header/header.reducer';
import productListReducer from './product-list/product-list.reducer';
import productReducer from './product/product.reducer';
import { HeaderState } from './header/header.state';
import { CartState } from './cart/cart.state';
import { HomeState } from './home/home.state';
import { ProductListState } from './product-list/product-list.state';
import { ProductState } from './product/product.state';
import { FooterState } from './footer/footer.state';
import footerReducer from './footer/footer.reducer';

export interface AppState {
	home: HomeState;
	cart: CartState;
	header: HeaderState;
	productList: ProductListState;
	footer: FooterState;
	product: ProductState;
}

export default combineReducers({
	home: homeReducer,
	cart: cartReducer,
	header: headerReducer,
	productList: productListReducer,
	product: productReducer,
	footer: footerReducer,
});
