import React from 'react';
import { Link } from 'react-router-dom';

export const HomeLoading = () => {

	return (
		<div className="home-page loading-mode">
			<section className="switchable switchable--switch space--xs home-slider">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="slider" />
						</div>
					</div>
				</div>
			</section>

			<section className="home-features">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="feature" />
						</div>
						<div className="col-md-4">
							<div className="feature" />
						</div>
						<div className="col-md-4">
							<div className="feature" />
						</div>
					</div>
				</div>
			</section>

			<section className="loading-home-products">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="row">
								<div className="col-md-12">
									<div className="featured-item feature-big" />
								</div>
								<div className="col-md-6">
									<div className="featured-item" />
								</div>
								<div className="col-md-6">
									<div className="featured-item" />
								</div>
								<div className="col-md-6">
									<div className="featured-item" />
								</div>
								<div className="col-md-6">
									<div className="featured-item" />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="row">
								<div className="col-md-6">
									<div className="featured-item" />
								</div>
								<div className="col-md-6">
									<div className="featured-item" />
								</div>
								<div className="col-md-6">
									<div className="featured-item" />
								</div>
								<div className="col-md-6">
									<div className="featured-item" />
								</div>
								<div className="col-md-12">
									<div className="featured-item feature-big" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
