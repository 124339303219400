import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CheckIcon from './check-icon.png';
import { goToTop } from '../../common/helpers';

type MyProps = any;
type MyState = any;
class Confirmation extends React.Component<MyProps, MyState> {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    goToTop();
  }

  render() {
    return (
      <section className="container cart-component">
        <Helmet>
          <title>Comandă efectuată cu success - Craftio</title>
        </Helmet>
      
        <div className="row confirmation-page">
          <div className="col-md-12">
            <div className="boxed boxed--border">
  
              <img className="confirmation-page-icon" src={CheckIcon} />
              <h2 className="confirmation-page-text">Iți multumim pentru comandă!</h2>
              <h4 className="confirmation-page-text">Vei fi contactat în curând pentru confirmarea comenzii.</h4>
  
            </div>
  
            <div className="row justify-content-end">
              <div className="col-lg-2 text-center-xs">
                <Link to="/">
                  <button type="submit" className="btn btn--secondary">Continuă cumpărăturile</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Confirmation;
