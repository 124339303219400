import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Home } from './home.component';
import { fetchHomeContent } from './home.actions';
import { AppState } from '../root-reducer';

export const mapStateToProps = ({ home, footer }: AppState) => ({
	...home,
	features: footer.constants
});

export const mapDispatchToProps = dispatch => {
	dispatch(fetchHomeContent());
	return bindActionCreators(
		{
			fetchHomeContent
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Home);
