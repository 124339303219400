import apiService from '../common/api.service';

export const UNLOAD_PRODUCT = 'UNLOAD_PRODUCT';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const fetchProduct = (id: string) => {
	return dispatch => {
		apiService.getProduct(id).then(data => {
			dispatch({ type: LOAD_PRODUCT, data });
		});
	};
};
