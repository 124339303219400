import { homeState, HomeState } from './home.state';
import { LOAD_HOME_CONTENT } from './home.actions';

export default (state = homeState, action) => {
	switch (action.type) {

		case LOAD_HOME_CONTENT:
			return loadContent(state, action);

		default:
			return state;
	}
};

const loadContent = (state: HomeState, action: any) => {
	const newState = { ...state };

	newState.sliders = action.data.sliders.map(slider => ({ image: slider.image.url, link: slider.url }));
	newState.featuredItems = action.data.products.map(product => ({
		id: product.id,
		name: product.name,
		url: product.url,
		price: product.price,
		image: product.photos[0].url,
	}));

	newState.loaded = true;

	return newState;
};
