import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Header } from './header.component';
import { AppState } from '../root-reducer';
import { UNLOAD_CATEGORY_PRODUCTS } from '../product-list/product-list.actions';

export const mapStateToProps = ({ cart, header }: AppState) => ({
	cartCount: cart.items.length,
	header,
});

export const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    unmountProducts: () => dispatch({ type: UNLOAD_CATEGORY_PRODUCTS })
  }, dispatch);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header);
