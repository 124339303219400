import React from 'react';

export const ProductLoading = () => {
	return (
		<div className="product-page loading-mode">
			<section className="container">
				<div className="row">
					<div className="col-md-6">
						<div className="loading-image" />
					</div>
					<div className="col-md-1" />
					<div className="col-md-5">
						<div className="loading-title" />
						<div className="loading-description" />

						<br/>

						<div className="loading-label" />
						<div className="loading-input" />

						<div className="loading-label" />
						<div className="loading-input" />

						<div className="loading-label" />
						<div className="loading-input" />

						<div className="loading-button" />
					</div>
				</div>
			</section>
		</div>
	);

};
