import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Delivery from './delivery.component';
import { AppState } from '../../root-reducer';
import { updateDeliveryInfo, completeOrder } from '../cart.actions';

export const mapStateToProps = ({ cart }: AppState) => ({
	cart
});

export const mapDispatchToProps = dispatch => bindActionCreators({
  updateDeliveryInfo,
  completeOrder
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Delivery);
