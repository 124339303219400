import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { goToTop } from '../common/helpers';

const Pages = ({ pages, loaded, history }) => {

	if (!loaded) {
		return <p style={{textAlign:'center', padding: '150px'}}>Loading...</p>;
	}

	const _pageUrl = window.location.pathname.split('/').slice(2)[0];

	const _page = pages.find(page => page.url === _pageUrl) || {};
  
  goToTop();

	return (
		<section className="container pages-component">
			<Helmet>
				<title>{_page.title} - Craftio</title>
			</Helmet>
			<div className="row justify-content-center">
				<div className="col-md-12">
					<div className="tabs-container tabs--vertical">
						<ul className="tabs">
							{pages.map(page => (
								<li className={_pageUrl === page.url ? 'active' : ''} key={page.id} onClick={() => history.push(`/pages/${page.url}`)}>
									<div className="tab__title">
										<span className="h5">{page.title}</span>
									</div>
								</li>
							))}
						</ul>
						<div className="tabs-content">
							<div>
								<h1 className="page-title">{_page.title}</h1>
								<p dangerouslySetInnerHTML={{__html: _page.content}} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default withRouter(Pages);
